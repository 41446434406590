// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from "./../../../../configs";

export const Container = styled.div`
  background-color: ${COLOR_CONFIG.DARK_BLUE};
  padding: 50px 0 77px;
  @media (max-width: 767px) {
    padding: 70px 35px 100px;
  }
`;

export const Wrapper = styled.div`
  max-width: 1140px;
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding: 0;
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 78px;
  line-height: 1.2;
  color: ${COLOR_CONFIG.WHITE};
  position: relative;
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  padding-top: 55px;
  padding-left: 65px;
  margin-bottom: 50px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 130px;
    height: 18px;
    background-color: ${COLOR_CONFIG.BLUE};
  }
  @media (max-width: 767px) {
    font-size: 48px;
    padding-top: 46px;
    margin-bottom: 84px;
    padding-left: 0;
    &:before {
      width: 80px;
      height: 16px;
    }
  }
`;

export const CardsList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  @media (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Card = styled.div`
  max-width: calc((100% - 16px) / 3);
  position: relative;
  @media (max-width: 767px) {
    max-width: 100%;
    margin-bottom: 15px;
  }
`;

export const Banner = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Main = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  text-align: center;
  color: ${COLOR_CONFIG.WHITE};
  width: 100;
  height: 60px;
  transform: translateY(-50%);
`;

export const Name = styled.p`
  font-size: 24px;
  text-transform: uppercase;
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  margin-bottom: 10px;
  position: relative;
  display: inline-block;
  &:after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: -2px;
    transform: translateX(-50%);
    width: 90%;
    height: 2px;
    background-color: ${COLOR_CONFIG.WHITE};
  }
`;

export const City = styled.p`
  font-size: 24px;
  text-transform: uppercase;
  font-family: ${FONT_CONFIG.nexaBlack};
`;

export const Footer = styled.div`
  position: absolute;
  background-color: rgba(15, 26, 61, 0.49);
  width: 100%;
  height: 85px;
  left: 0;
  bottom: 20px;
  text-align: center;
  padding-top: 20px;
  color: ${COLOR_CONFIG.WHITE};
`;

export const Street = styled.p`
  font-size: 18px;
  line-height: 1.2;
  font-family: ${FONT_CONFIG.nexaBold};
`;

export const Address = styled.p`
  font-size: 18px;
  line-height: 1.2;
  font-family: ${FONT_CONFIG.nexaBold};
`;
