// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from "./../../../../configs";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1140px;
  height: auto;
  padding: 0 15px;
  margin: 0 auto 65px;
  position: relative;
  @media (max-width: 767px) {
    padding: 0 35px;
    margin-bottom: 40px;
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 78px;
  line-height: 1.2;
  color: ${COLOR_CONFIG.DARK_BLUE};
  margin-bottom: 13px;
  position: relative;
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  padding: 51px 0 0 47px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: -20px;
    width: 130px;
    height: 18px;
    background-color: ${COLOR_CONFIG.DARK_BLUE};
  }
  @media (max-width: 767px) {
    padding: 50px 0 0 0;
    font-size: 48px;
    margin-bottom: 20px;
    &:before {
      left: 0;
      top: 0;
      width: 80px;
      height: 16px;
    }
  }
`;

export const Content = styled.p`
  font-size: 18px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  line-height: 1.3;
  margin-bottom: 43px;
  font-family: ${FONT_CONFIG.nexaBook};
  max-width: 636px;
  margin: 0 0 77px auto;
  padding-bottom: 51px;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 130px;
    height: 18px;
    background-color: ${COLOR_CONFIG.BLUE};
  }
  @media (max-width: 767px) {
    max-width: 100%;
    padding-left: 35px;
    font-size: 16px;
    margin-bottom: 45px;
    &::after {
      width: 80px;
      height: 16px;
    }
  }
`;

export const CardsList = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 50px;
  @media (max-width: 767px) {
    display: block;
    margin-bottom: 40px;
  }
`;

export const Card = styled.div`
  width: 100%;
  max-width: calc((100% - 40px) / 3);
  background-color: ${COLOR_CONFIG.GRAY_LIGHT};
  border-bottom: 19px solid ${COLOR_CONFIG.DARK_BLUE};
  margin: 0 30px;
  @media (max-width: 767px) {
    max-width: 100%;
    margin: 0 0 20px;
  }
`;

export const BannerWrapper = styled.div`
  width: 100%;
  height: 300px;
  margin-bottom: 21px;
`;

export const Banner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const CardTag = styled.p`
  font-size: 18px;
  line-height: 1.3;
  font-family: ${FONT_CONFIG.nexaBold};
  margin-bottom: 4px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  padding: 0 20px;
`;

export const CardTitle = styled.p`
  font-size: 18px;
  line-height: 1.3;
  font-family: ${FONT_CONFIG.nexaBook};
  margin-bottom: 17px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  padding: 0 20px;
`;

export const AutoLogos = styled.img`
  display: block;
  width: calc(100% + 180px);
  height: auto;
  margin: 0 -90px;
  @media (max-width: 1400px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
  }
`;

export const LogosList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const LogoItem = styled.a``;

export const LogoImg = styled.img`
  display: block;
  height: 60px;
  width: auto;
`;
