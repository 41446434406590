// @flow

/* REACT */
import React from 'react';

/* MODULES */
import { Link } from "react-router-dom";

/* CUSTOM MODULES */

/* CONFIGS */
import { MAIN_CONFIG } from './../../../../configs';

/* STYLES */
import {
  Container, Wrapper, Main, Title, Content, Img
} from './styles';

/* TYPES */
import type { Node } from 'react';

type _t_props = {|
  data: Object,
|};

const AboutUs = React.forwardRef((props: _t_props, ref): Node => {

  const { title, content, sliders } = props.data;

  return (
    <Container ref={ref} id="aboutUs">
      <Wrapper>
        <Main>
          <Title>{title}</Title>
          <Content dangerouslySetInnerHTML={{ __html: content }} />
          <Link to="/contact-us" className="link-btn">Contact Us</Link>
        </Main>
        <Img src={`${MAIN_CONFIG.CDN_URL}${sliders[0]}`} />
      </Wrapper>
    </Container>
  );
});

export default AboutUs;
