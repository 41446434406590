// @flow

/* REACT */
import React, { useEffect, useState } from 'react';

/* MODULES */
import { Link, Redirect } from 'react-router-dom';
import moment from 'moment';
import { TwitterShareButton, LinkedinShareButton } from 'react-share';

/* CUSTOM MODULES */
import { PageContainer } from './../../components';

/* CONFIGS */
import { IMAGES_CONFIG, MAIN_CONFIG } from './../../configs';

/* STYLES */
import {
  BannerWrapper, Banner, Wrapper, IconWrapper, Icon, Title, Date, Content,
  ShareWrapper, ShareLabel, SocialIcon,
} from './styles';

/* TYPES */
import type { Node } from 'react';

// type _t_props = {||};

export default (): Node => {
  const [newsInfo, setNewsInfo] = useState<Object>(null);
  const [isNotExistingNews, setNotExistingNews] = useState<Object>(false);

  const getData = async () => {
    try {
      const slug = window.location.pathname.split('/news/')[1];
      const response = await fetch(`${MAIN_CONFIG.API_URL}news/${slug}`);
      const dataResp = await response.json();
      setNewsInfo(dataResp);
    } catch (e) {
      setNotExistingNews(true);
      console.warn('GET DATA NEWS PAGE ERROR', e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  if (isNotExistingNews) {
    return <Redirect to="/404" />;
  }

  const shareUrl = `https://drivvn.com${window.location.pathname}`;

  return (
    <PageContainer isLoading={!newsInfo}>
      {
        newsInfo
          ? (
            <>
              <BannerWrapper>
                <Banner src={`${MAIN_CONFIG.CDN_URL}${newsInfo.image}`} />
              </BannerWrapper>
              <Wrapper>
                <Link to="/?to=news">
                  <IconWrapper>
                    <Icon src={IMAGES_CONFIG.iconArrowLeft} />
                  </IconWrapper>
                  Back
                </Link>
                <Title>{newsInfo.title}</Title>
                <Date>{`Published on ${moment(newsInfo?.published_at || '').format('MMMM DD, YYYY')}`}</Date>
                <Content dangerouslySetInnerHTML={{ __html: newsInfo?.content }} />
                <ShareWrapper>
                  <ShareLabel>Share this article</ShareLabel>
                  <LinkedinShareButton
                    url={shareUrl}
                    windowWidth={750}
                    windowHeight={600}
                  >
                    <SocialIcon src={IMAGES_CONFIG.linkedinIcon} />
                  </LinkedinShareButton>
                  <TwitterShareButton
                    url={shareUrl}
                    title={newsInfo.title}
                  >
                    <SocialIcon src={IMAGES_CONFIG.twitterIcon} />
                  </TwitterShareButton>
                </ShareWrapper>
              </Wrapper>
            </>
          )
          : null
      }
    </PageContainer>
  );
};
