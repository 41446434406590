// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from '../../configs';

export const BannerWrapper = styled.div`
  width: 100%;
  height: 500px;
  margin-top: 85px;
  margin-bottom: 42px;
  @media (max-width: 1024px) {
    margin-bottom: 0;
  }
`;

export const Banner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Wrapper = styled.div`
  max-width: 950px;
  width: 100%;
  margin: 0 auto;
  & > a {
    display: flex;
    max-width: 90px;
    margin-bottom: 30px;
    color: ${COLOR_CONFIG.DARK_BLUE};
    font-size: 18px;
    line-height: 30px;
    font-family: ${FONT_CONFIG.nexaHeavy};
    &:hover {
      color: ${COLOR_CONFIG.BLUE};
    }
  }
  @media (max-width: 1024px) {
    max-width: 100%;
    padding: 70px 35px;
  }
`;

export const IconWrapper = styled.span`
  width: 24px;
  height: 24px;
  background-color: ${COLOR_CONFIG.BLUE};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
`;

export const Icon = styled.img``;

export const Title = styled.p`
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  font-size: 48px;
  line-height: 1.2;
  color: ${COLOR_CONFIG.DARK_BLUE};
  margin-bottom: 42px;
  padding-right: 95px;
  @media (max-width: 767px) {
    font-size: 36px;
    margin-bottom: 20px;
  }
`;

export const Date = styled.p`
  font-family: ${FONT_CONFIG.nexaBold};
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 62px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  @media (max-width: 767px) {
    font-size: 17px;
    margin-bottom: 36px;
  }
`;

export const Content = styled.div`
  padding: 0 95px 70px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  p {
    margin-bottom: 35px;
    font-family: ${FONT_CONFIG.nexaBook};
    font-size: 20px;
    line-height: 1.4;
    color: ${COLOR_CONFIG.DARK_BLUE};
    @media (max-width: 767px) {
      font-size: 17px;
      margin-bottom: 15px;
    }
  }
  strong {
    font-family: ${FONT_CONFIG.nexaHeavy};
    font-size: 24px;
    line-height: 1.4;
    margin-bottom: 15px;
    color: ${COLOR_CONFIG.DARK_BLUE};
    @media (max-width: 767px) {
      font-size: 22px;
    }
  }
  blockquote {
    background-color: rgba(191, 191, 191, 0.17);
    padding: 25px 25px 0;
    font-family: ${FONT_CONFIG.nexaBookItalic};
    color: #353535;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 25px;
    overflow: hidden;
    @media (max-width: 767px) {
      font-size: 17px;
    }
    a {
      font-size: 20px;
      line-height: 24px;
      color: #783671;
      padding-left: 3px;
      font-family: ${FONT_CONFIG.nexaBookItalic};
      &:hover {
        text-decoration: underline;
      }
      @media (max-width: 767px) {
        font-size: 17px;
      }
    }
    p {
      margin-bottom: 25px;
      font-family: ${FONT_CONFIG.nexaBookItalic};
    }
    strong {
      font-family: ${FONT_CONFIG.nexaHeavyItalic};
    }
  }
  img {
    display: block;
    max-width: 100% !important;
    height: auto !important;
  }
  ol {
    padding-left: 20px;
    list-style: decimal;
    margin-bottom: 35px;
    li {
      font-family: ${FONT_CONFIG.nexaBook};
      font-size: 20px;
      line-height: 1.2;
      color: ${COLOR_CONFIG.DARK_BLUE};
      padding-left: 5px;
      margin-bottom: 15px;
      @media (max-width: 767px) {
        font-size: 17px;
        margin-bottom: 10px;
      }
    }
  }
  @media (max-width: 1024px) {
    padding: 0;
  }
`;

export const ShareWrapper = styled.div`
  display: flex;
  margin-bottom: 70px;
  button {
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 16px;
    background-color: ${COLOR_CONFIG.DARK_BLUE} !important;
  }
  @media (max-width: 1024px) {
    margin-bottom: 0;
  }
`;

export const ShareLabel = styled.p`
  font-family: ${FONT_CONFIG.nexaBook};
  font-size: 18px;
  line-height: 26px;
  color: ${COLOR_CONFIG.GRAY_2};
  margin-right: 23px;
`;

export const SocialLink = styled.a`
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
  background-color: ${COLOR_CONFIG.DARK_BLUE};
`;

export const SocialIcon = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;
