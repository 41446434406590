// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from "./../../../../configs";

export const Hero = styled.div`
  position: relative;
  margin-bottom: 130px;
  @media (max-width: 960px) {
    padding-top: 85px;
  }
  @media (max-width: 767px) {
    padding-top: 0;
    height: 100vh;
    margin-bottom: 40px;
  }
`;

export const HeroImg = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  @media (max-width: 767px) {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 100%;
  max-width: 700px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 960px) {
    padding-top: 85px;
  }
  @media (max-width: 767px) {
    padding: 0 55px;
  }
`;

export const Logo = styled.img`
  margin: 0 auto 25px 0;
  @media (max-width: 767px) {
    width: 100%;
    max-width: 290px;
    margin: 0 auto 20px 0;
    display: none;
  }
`;

export const Text = styled.p`
  color: ${COLOR_CONFIG.WHITE};
  font-size: 70px; 
  text-transform: uppercase;
  line-height: 1.1;
  margin-bottom: 25px;
  font-family: ${FONT_CONFIG.bebasNeueBook};
  & + a {
    display: block;
    margin: 0 auto 0 0;
    width: 165px;
    height: 42px;
    line-height: 42px;
    border-radius: 21px;
    background-color: ${COLOR_CONFIG.BLUE};
    font-size: 16px;
    color: ${COLOR_CONFIG.DARK_BLUE};
    font-family: ${FONT_CONFIG.nexaHeavy};
    text-align: center;
    &:hover {
      background-color: ${COLOR_CONFIG.DARK_BLUE};
      color: ${COLOR_CONFIG.WHITE};
    }
  }
  @media (max-width: 767px) {
   font-size: 48px;
   line-height: 52px;
   margin-bottom: 13px;
   text-align: center;
   & + a {
      margin: 0 auto;
   }
  }
`;

export const Button = styled.button`
  padding-top: 3px;
  margin: 0 auto 0 0;
  width: 165px;
  height: 42px;
  border-radius: 21px;
  background-color: ${COLOR_CONFIG.BLUE};
  font-size: 16px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-family: ${FONT_CONFIG.nexaHeavy};
  &:hover {
    background-color: ${COLOR_CONFIG.DARK_BLUE};
    color: ${COLOR_CONFIG.BLUE};
  }
`;
