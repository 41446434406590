// @flow

/* REACT */
import React, { useState } from 'react';

/* MODULES */
import { Redirect } from "react-router-dom";

/* CUSTOM MODULES */

/* CONFIGS */
import { IMAGES_CONFIG } from '../../configs';

/* STYLES */
import {
  Wrapper, LogoBtn, Logo, LogoText, Header, Form, Input, Btn,
} from './styles';

/* TYPES */
import type { Node } from 'react';

// type _t_props = {||};

export default (): Node => {
  const [password, setPassword] = useState<string>('');
  // const [password, setPassword] = useState<string>('Dr1vvnW3bs1t3');
  const [isCorrectPassword, setCorrectPassword] = useState<boolean>(false);
  const [isFormVisible, setFormVisible] = useState<boolean>(false);

  const submitForm = (e: Object) => {
    e.preventDefault();
    const correctPasswordValue = 'Dr1vvnW3bs1t3';
    if (password === correctPasswordValue) {
      setCorrectPassword(true);
    }
  };

  const onChange = (e: Object) => {
    setPassword(e.target.value);
  };

  if (isCorrectPassword) {
    return <Redirect to="/" />;
  }

  return (
    <Wrapper bg={IMAGES_CONFIG.holdBg}>
      <Header>
        <LogoBtn onClick={() => setFormVisible(true)}>
          <Logo src={IMAGES_CONFIG.logoSquare} />
        </LogoBtn>
        {
          isFormVisible
            ? (
              <Form onSubmit={submitForm}>
                <Input
                  placeholder="Enter Password"
                  value={password}
                  onChange={onChange}
                />
                <Btn type="submit">Sign In</Btn>
              </Form>
            )
            : null
        }
      </Header>
      <LogoText src={IMAGES_CONFIG.logoBlue} />
    </Wrapper>
  );
};
