// @flow

import Logo from './../static/img/logo.svg';
import HeroBg from './../static/img/hero-bg.png';
import LinkedinIcon from './../static/img/linkedin.svg';
import TwitterIcon from './../static/img/twitter.svg';
import LogoBlue from './../static/img/logo-Blue.svg';
import WhatWeDoMainImg from './../static/img/what-we-do-main-img.png';
import IconClock from './../static/img/icon-clock.svg';
import IconGears from './../static/img/icon-gears.svg';
import IconTarget from './../static/img/icon-target.svg';
import HowWeDoIt1 from './../static/img/howwedoit1.png';
import HowWeDoIt2 from './../static/img/howwedoit2.png';
import HowWeDoIt3 from './../static/img/howwedoit3.png';
import News1 from './../static/img/news1.png';
import News2 from './../static/img/news2.png';
import News3 from './../static/img/news3.png';
import AutoLogos from './../static/img/autoLogos.png';
import Team from './../static/img/team.png';
import IconSend from './../static/img/icon-send.svg';
import IconArrowRight from './../static/img/icon-arrow-right.svg';
import HoldBg from './../static/img/hold-bg.png';
import LogoSquare from './../static/img/logo-square.svg';
import HoWeDoIt from './../static/img/how-we-do-it.png';
import WhyWeDoIt from './../static/img/why-we-do-it.png';
import AutoLogosBar from './../static/img/auto-logos-bar.png';
import Client1 from './../static/img/clients-1.png';
import Client2 from './../static/img/clients-2.png';
import Client3 from './../static/img/clients-3.png';
import Office1 from './../static/img/office-1.png';
import Office2 from './../static/img/office-2.png';
import Office3 from './../static/img/office-3.png';
import QuestionIcon from './../static/img/question-mark.svg';
import CloseIcon from './../static/img/close.svg';
import Belief from './../static/img/belief.png';
import SingleNewsBanner from './../static/img/single-news-banner.png';
import ArrowLeft from './../static/img/arrow-left.svg';
import IconClose from './../static/img/icon-close.svg';

export default {
  logo: Logo,
  heroBg: HeroBg,
  linkedinIcon: LinkedinIcon,
  twitterIcon: TwitterIcon,
  logoBlue: LogoBlue,
  whatWeDoMainImg: WhatWeDoMainImg,
  iconClock: IconClock,
  iconGears: IconGears,
  iconTarget: IconTarget,
  howWeDoIt1: HowWeDoIt1,
  howWeDoIt2: HowWeDoIt2,
  howWeDoIt3: HowWeDoIt3,
  news1: News1,
  news2: News2,
  news3: News3,
  autoLogos: AutoLogos,
  team: Team,
  iconSend: IconSend,
  iconArrowRight: IconArrowRight,
  holdBg: HoldBg,
  logoSquare: LogoSquare,
  hoWeDoIt: HoWeDoIt,
  whyWeDoIt: WhyWeDoIt,
  autoLogosBar: AutoLogosBar,
  client1: Client1,
  client2: Client2,
  client3: Client3,
  office1: Office1,
  office2: Office2,
  office3: Office3,
  questionIcon: QuestionIcon,
  closeIcon: CloseIcon,
  belief: Belief,
  singleNewsBanner: SingleNewsBanner,
  iconArrowLeft: ArrowLeft,
  iconClose: IconClose,
};
