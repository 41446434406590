// @flow

/* MODULES */
import styled, { css } from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from '../../configs';

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${COLOR_CONFIG.WHITE};
  width: 100%;
  height: 85px;
  display: flex;
  align-items: center;
  padding-left: 63px;
  z-index: 1000;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  & > a {
    display: block;
    max-width: 131px;
  }
  @media (max-width: 1100px) {
    padding: 0 5px 0 10px;
    & > a {
      max-width: 90px;
    }
  }
  @media (max-width: 767px) {
    /* background-color: ${COLOR_CONFIG.TRANSPARENT}; */
    /* box-shadow: none; */
    padding: 10px 15px;
    height: auto;
    & > a {
      max-width: 131px;
      margin-left: 25px;
    }
  }
`;

export const Btn = styled.button`
  display: block;
  max-width: 131px;
  @media (max-width: 1100px) {
    max-width: 90px;
  }
  @media (max-width: 767px) {
    max-width: 131px;
    margin-left: 25px;
  }
`;

export const Logo = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;

export const Nav = styled.nav`
  display: flex;
  align-items: center;
  margin: 0 60px 0 auto;
  a {
    margin: 0 13px;
    color: ${COLOR_CONFIG.DARK_BLUE};
    font-size: 18px;
    font-family: ${FONT_CONFIG.nexaHeavy};
    &:hover {
      color: ${COLOR_CONFIG.BLUE};
    }
  }
  @media (max-width: 1100px) {
    margin: 0 0 0 auto;
    a {
      margin: 0 5px;
      font-size: 16px;
    }
  }
  @media (max-width: 767px) {
    display: block;
    transform: translateX(100%);
    transition: all 0.3s ease-in-out;
    background-color: ${COLOR_CONFIG.DARK_BLUE};
    height: 100vh;
    min-width: 325px;
    max-width: 76%;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    padding: 153px 60px 60px;
    & > button {
      display: flex;
    }
    a {
      display: block;
      color: ${COLOR_CONFIG.WHITE};
      margin-bottom: 36px;
      &:hover {
        color: ${COLOR_CONFIG.BLUE};
      }
    }
    ${props => props.isOpenMenu && css`
      transform: translateX(0);
    `}
  }
`;

export const LinkOutside = styled.a`
  margin: 0 13px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-size: 18px;
  font-family: ${FONT_CONFIG.nexaHeavy};
  &:hover {
    color: ${COLOR_CONFIG.BLUE};
  }
`;

export const ToggleBtn = styled.button`
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 60px;
  height: 60px;
  background-color: ${COLOR_CONFIG.BLUE};
  &:hover {
    background-color: ${COLOR_CONFIG.DARK_BLUE};
  }
  @media (max-width: 767px) {
    display: flex;
    margin: 0 0 0 auto;
  }
`;

export const ToggleBtnLine = styled.span`
  width: 30px;
  height: 2px;
  background-color: ${COLOR_CONFIG.WHITE};
  margin-bottom: ${props => props.last ? 0 : '8px'};
`;

export const CloseNavBtn = styled.button`
  position: absolute;
  top: 13px;
  left: 18px;
  display: none;
  width: 60px;
  height: 60px;
  background-color: ${COLOR_CONFIG.TRANSPARENT};
  align-items: center;
  justify-content: center;
`;

export const Img = styled.img``;

export const LinkWrapper = styled.span``;
