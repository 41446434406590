// @flow

/* REACT */
import React, { useState, useEffect, useRef } from 'react';

/* CUSTOM MODULES */
import Hero from './components/Hero';
import WhatWeDo from './components/WhatWeDo';
import HowWeDoIt from './components/HowWeDoIt';
import WhyWeDoit from './components/WhyWeDoIt';
import CLients from './components/Clients';
import News from './components/News';
// import Proof from './components/Proof';
import AboutUs from './components/AboutUs';
import Offices from './components/Offices';
import { PageContainer } from './../../components';

/* CONFIGS */
import { MAIN_CONFIG } from './../../configs';

/* STYLES */

export default () => {
  const [data, setData] = useState<Object>(null);
  const clientsRef = useRef(null);
  const howWeDoItRef = useRef(null);
  const whatWeDoRef = useRef(null);
  const whyWeDoItRef = useRef(null);
  const aboutUsRef = useRef(null);
  const newsRef = useRef(null);

  const scrollToRef = ref => {
    const section = ref?.current?.offsetTop;
    if (section) {
      window.scrollTo({
        top: section - 120,
        left: 0,
        behavior: 'smooth'
      });
    }
  };

  const getData = async () => {
    try {
      const response = await fetch(`${MAIN_CONFIG.API_URL}main`);
      const dataResp = await response.json();
      setData(dataResp);
      const query = window.location.search.split('to=')[1];
      const queryScrollConfig = {
        whatWeDo: whatWeDoRef,
        howWeDoIt: howWeDoItRef,
        whyWeDoIt: whyWeDoItRef,
        clients: clientsRef,
        aboutUs: aboutUsRef,
        news: newsRef,
      };
      if (query && queryScrollConfig[query]) {
        setTimeout(() => {
          scrollToRef(queryScrollConfig[query]);
        }, 100);
      }
    } catch (e) {
      console.warn('GET DATA HOME PAGE ERROR', e);
    }
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  return (
    <PageContainer isLoading={!data}>
      {
        data
          ? (
            <>
              <Hero data={data.homepage} />
              <WhatWeDo ref={whatWeDoRef} data={data.whatWeDo} />
              <HowWeDoIt ref={howWeDoItRef} data={data.howWeDo} />
              <WhyWeDoit ref={whyWeDoItRef} data={data.whyWeDo} />
              <CLients ref={clientsRef} data={data.clients} />
              <News ref={newsRef} data={data.news} />
              {/* <Proof /> */}
              <AboutUs ref={aboutUsRef} data={data.aboutUs} />
              <Offices data={data.offices} />
            </>
          )
          : null
      }
    </PageContainer>
  );
};
