// @flow

/* REACT */
import React, { useState, useEffect } from 'react';

/* MODULES */

/* CUSTOM MODULES */
import { PageContainer } from './../../components';

/* CONFIGS */
import { MAIN_CONFIG } from './../../configs';

/* STYLES */
import {
  Wrapper, LeftColumn, RightColumn, TitleWrapper, ImgWrapper, Title, Img, Content,
} from './styles';

/* TYPES */
import type { Node } from 'react';

// type _t_props = {||};

export default (): Node => {
  const [data, setData] = useState<Object>(null);

  const getData = async () => {
    try {
      const response = await fetch(`${MAIN_CONFIG.API_URL}page/our-belief`);
      const { page } = await response.json();
      setData(page);
    } catch (e) {
      console.warn('GET DATA our-belief ERROR', e);
    }
  };

  useEffect(() => {
    getData();
  }, []); // eslint-disable-line

  return (
    <PageContainer isLoading={!data}>
      {
        data ? (
          <Wrapper>
            <LeftColumn>
              <TitleWrapper>
                <Title>{data.title}</Title>
              </TitleWrapper>
              <ImgWrapper>
                <Img src={`${MAIN_CONFIG.CDN_URL}${data.image}`} />
              </ImgWrapper>
            </LeftColumn>
            <RightColumn>
              <Content dangerouslySetInnerHTML={{ __html: data?.content }} />
              <ImgWrapper mobile>
                <Img src={`${MAIN_CONFIG.CDN_URL}${data.image}`} />
              </ImgWrapper>
            </RightColumn>
          </Wrapper>
        ) : null
      }
    </PageContainer>
  );
};
