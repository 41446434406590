// @flow

/* REACT */
import React, { useState, useEffect } from 'react';

/* MODULES */
import { Link } from 'react-router-dom';

/* CUSTOM MODULES */
import { PageContainer } from './../../components';

/* CONFIGS */
import { IMAGES_CONFIG, COLOR_CONFIG, MAIN_CONFIG } from './../../configs';

/* STYLES */
import {
  Wrapper, Title,
  Form, Row, Text,
  // SelectWrapper, Selected, SelectList, SelectItem,
  Input, Message, BtnSubmit,
  // HintBtn, HintDescription, , Hint
  SuccessMessage, TextSuccess, BtnClose, Img,
  CheckboxWrapper, CheckIcon, CheckLabel,
} from './styles';

/* TYPES */
import type { Node } from 'react';

// type _t_props = {||};

// const purposesList = [
//   {
//     value: 'advice',
//     label: 'Product Advice',
//   },
//   {
//     value: 'care',
//     label: 'Customer Care',
//   },
//   {
//     value: 'partnership',
//     label: 'Partnership Enquiry',
//   },
//   {
//     value: 'press',
//     label: 'Press & Comms',
//   },
//   {
//     value: 'general',
//     label: 'General Enquiries',
//   },
// ];

export default (): Node => {
  // const [isVisibleDropdown, setVisibleDropdown] = useState<boolean>(false);
  // const [subject, setSubject] = useState<Object>(null);
  const [firstName, setFirstName] = useState<string>('');
  const [lastName, setLastName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [message, setMessage] = useState<string>('');
  const [isReceivingEmail, setReceivingEmail] = useState<boolean>(false);
  const [isConfirmedPolicy, setConfirmedPolicy] = useState<boolean>(false);
  const [isFormSent, setFormSent] = useState<boolean>(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const sendFormData = async (data: Object) => {
    const settings = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(`${MAIN_CONFIG.API_URL}contacts`, settings);
      const resp = await response.json();
      if (resp.status) {
        setFormSent(true);
        setFirstName('');
        setLastName('');
        setEmail('');
        setMessage('');
        setReceivingEmail(false);
        setConfirmedPolicy(false);
        setTimeout(() => {
          setFormSent(false);
        }, 3000);
      }
    } catch (e) {
      console.warn('SEND FORM DATA ERROR', e);
    }
  };

  const submitForm = (e: Object) => {
    if (e) {
      e.preventDefault();
    }
    const payload = {
      firstname: firstName,
      lastname: lastName,
      email,
      purpose: "general",
      message,
    };
    sendFormData(payload);
  };

  // const setDropdownValue = useCallback((value: Object) => {
  //   setSubject(value);
  //   setVisibleDropdown(false);
  // }, []);

  return (
    <PageContainer isLoading={false} bgColor={COLOR_CONFIG.DARK_BLUE}>
      <Wrapper>
        <Title>Contact US</Title>
        <Form onSubmit={submitForm}>
          <Text>How can we help you today?</Text>
          <Text bold>*All fields are required to submit the form.</Text>
          {/* <SelectWrapper>
            <Selected
              isOpened={isVisibleDropdown}
              onClick={() => setVisibleDropdown(!isVisibleDropdown)}
            >
              {subject ? subject.label : 'Please select'}
            </Selected>
            <SelectList isVisible={isVisibleDropdown}>
              {
                purposesList.map(i => (
                  <SelectItem
                    key={i.value}
                    onClick={() => setDropdownValue(i)}
                    isSelected={subject ? subject.label === i.label : false}
                  >
                    {i.label}
                  </SelectItem>
                ))
              }
            </SelectList>
          </SelectWrapper> */}
          <Row>
            <Input
              placeholder="Your first name*"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
            <Input
              placeholder="Your last name*"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </Row>
          <Input
            placeholder="Your email address*"
            type="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
          <Message
            placeholder="Please tell us a little about how we can help*"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
          <CheckboxWrapper onClick={() => setReceivingEmail(!isReceivingEmail)}>
            <CheckIcon isSelected={isReceivingEmail} />
            <CheckLabel>
              By completing this form you are consenting to receiving communication from Drivvn Ltd.
            </CheckLabel>
          </CheckboxWrapper>
          <CheckboxWrapper onClick={() => setConfirmedPolicy(!isConfirmedPolicy)}>
            <CheckIcon isSelected={isConfirmedPolicy} />
            <CheckLabel>
              I understand that my information will be used to reply to my message, and by submitting my details I confirm that I have read and agree with Drivvn’s
              <Link to="/privacy-policy">privacy policy</Link>
              and
              <Link to="cookie-policy">cookie policy</Link>
              .
            </CheckLabel>
          </CheckboxWrapper>
          {/* <Hint> */}
          {/* By completing this form you are consenting to receiving communication from Drivvn Ltd */}
          {/* <HintBtn>
              <Img src={IMAGES_CONFIG.questionIcon} />
            </HintBtn> */}
          {/* </Hint> */}
          {/* <HintDescription>
            I understand that my information will be used to reply to my message, and by submitting my details I confirm that I have read and agree with Drivvn’s
            <Link to="/privacy-policy">privacy policy</Link>
            and
            <Link to="cookie-policy">cookie policy</Link>
            .
          </HintDescription> */}
          <BtnSubmit
            type="submit"
            disabled={!isConfirmedPolicy || !firstName || !lastName || !email || !message}
          >
            Send
          </BtnSubmit>
        </Form>
      </Wrapper>
      {
        isFormSent ? (
          <SuccessMessage>
            <Wrapper>
              <TextSuccess>Your enquiry has been sent successfully!</TextSuccess>
              <BtnClose onClick={() => setFormSent(false)}>
                <Img src={IMAGES_CONFIG.closeIcon} />
              </BtnClose>
            </Wrapper>
          </SuccessMessage>
        ) : null
      }
    </PageContainer>
  );
};
