/* eslint-disable */

/* MODULES */
import { createGlobalStyle } from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from "./../configs";

/* FONTS */
import NexaHeavyTtf from './../static/fonts/Nexa-Heavy/NexaHeavy.ttf';
import NexaHeavyWoff from './../static/fonts/Nexa-Heavy/NexaHeavy.woff';
import NexaHeavyEot from './../static/fonts/Nexa-Heavy/NexaHeavy.eot';

import NexaRegularTtf from './../static/fonts/Nexa-Regular/NexaRegular.ttf';
import NexaRegularWoff from './../static/fonts/Nexa-Regular/NexaRegular.woff';
import NexaRegularEot from './../static/fonts/Nexa-Regular/NexaRegular.eot';

import NexaBookTtf from './../static/fonts/Nexa-Book/NexaBook.ttf';
import NexaBookWoff from './../static/fonts/Nexa-Book/NexaBook.woff';
import NexaBookWEot from './../static/fonts/Nexa-Book/NexaBook.eot';

import NexaBoldTtf from './../static/fonts/NexaBold/NexaBold.ttf';
import NexaBoldWoff from './../static/fonts/NexaBold/NexaBold.woff';
import NexaBoldEot from './../static/fonts/NexaBold/NexaBold.eot';

import ConvNexaLightTtf from './../static/fonts/Naxa-Light/NexaLight.ttf';
import ConvNexaLightWoff from './../static/fonts/Naxa-Light/NexaLight.woff';
import ConvNexaLightEot from './../static/fonts/Naxa-Light/NexaLight.eot';

import Conv_NexaBlackTtf from './../static/fonts/NexaBlack/NexaBlack.ttf';
import Conv_NexaBlackWoff from './../static/fonts/NexaBlack/NexaBlack.woff';
import Conv_NexaBlackEot from './../static/fonts/NexaBlack/NexaBlack.eot';

import BebasNeueBookTtf from './../static/fonts/BebasNeueBook/BebasNeueBook.ttf';
import BebasNeueBookWoff from './../static/fonts/BebasNeueBook/BebasNeueBook.woff';
import BebasNeueBookEot from './../static/fonts/BebasNeueBook/BebasNeueBook.eot';

import BebasNeueRegularTtf from './../static/fonts/BebasNeueRegular/BebasNeueRegular.ttf';
import BebasNeueRegularWoff from './../static/fonts/BebasNeueRegular/BebasNeueRegular.woff';
import BebasNeueRegularEot from './../static/fonts/BebasNeueRegular/BebasNeueRegular.eot';

import NexaHeavyItalicTtf from './../static/fonts/NexaHeavyItalic/NexaHeavyItalic.ttf';
import NexaHeavyItalicWoff from './../static/fonts/NexaHeavyItalic/NexaHeavyItalic.woff';
import NexaHeavyItalicEot from './../static/fonts/NexaHeavyItalic/NexaHeavyItalic.eot';

import NexaBookItalicTtf from './../static/fonts/NexaBookItalic/NexaBookItalic.ttf';
import NexaBookItalicWoff from './../static/fonts/NexaBookItalic/NexaBookItalic.woff';
import NexaBookItalicEot from './../static/fonts/NexaBookItalic/NexaBookItalic.eot';

// eslint-disable-next-line no-unused-expression
const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: "Nexa-Heavy";
    src: url(${NexaHeavyEot});
    src: url(${NexaHeavyTtf}) format("truetype"),
      url(${NexaHeavyWoff}) format("woff");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Nexa-Regular";
    src: url(${NexaRegularEot});
    src: url(${NexaRegularTtf}) format("truetype"),
      url(${NexaRegularWoff}) format("woff");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "Nexa-Book";
    src: url(${NexaBookWEot});
    src: url(${NexaBookTtf}) format("truetype"),
      url(${NexaBookWoff}) format("woff");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "NexaBold";
    src: url(${NexaBoldEot});
    src: url(${NexaBoldTtf}) format("truetype"),
      url(${NexaBoldWoff}) format("woff");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Conv_NexaLight';
    src: url(${ConvNexaLightEot});
    src: url(${ConvNexaLightTtf}) format("truetype"),
      url(${ConvNexaLightWoff}) format("woff");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Conv_NexaBlack';
    src: url(${Conv_NexaBlackEot});
    src: url(${Conv_NexaBlackWoff}) format('woff'),
      url(${Conv_NexaBlackTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Conv_NexaHeavyItalic';
    src: url(${NexaHeavyItalicEot});
    src: url(${NexaHeavyItalicWoff}) format('woff'),
      url(${NexaHeavyItalicTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: 'Conv_NexaBookItalic';
    src: url(${NexaBookItalicEot});
    src: url(${NexaBookItalicWoff}) format('woff'),
      url(${NexaBookItalicTtf}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "BebasNeueBook";
    src: url(${BebasNeueBookEot});
    src: url(${BebasNeueBookTtf}) format("truetype"),
      url(${BebasNeueBookWoff}) format("woff");
    font-weight: normal;
    font-style: normal;
  }
  @font-face {
    font-family: "BebasNeueRegular";
    src: url(${BebasNeueRegularEot});
    src: url(${BebasNeueRegularTtf}) format("truetype"),
      url(${BebasNeueRegularWoff}) format("woff");
    font-weight: normal;
    font-style: normal;
  }
  * {
    box-sizing: border-box;
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(255,255,255,0);
    -webkit-tap-highlight-color: none;
  }
  *:before,
  *:after {
    box-sizing: border-box;
  }
  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  body {
    min-width: 320px;
    width: 100vw;
    height: 100%;
    line-height: 1;
    font-family: 'Nexa-Regular', sans-serif;
    font-size: 14px;
    font-weight: 400;
    overflow-x: hidden;
    color: ${COLOR_CONFIG.GRAY_DARK};
     /* -webkit-overflow-scrolling: touch; */
    &.hidden {
      overflow-y: hidden;
    }
  }
  p {
    max-width: 100%;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  a {
    cursor: pointer;
    color: ${COLOR_CONFIG.GOLD};
    text-decoration: none;
    transition: all 0.3s ease-in-out;
    outline: none;
    &:focus,
    &:active {
      outline: none;
    }
  }
  a:hover {
    text-decoration: none;
  }
  button {
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: none;
  }
  h1, h2, h3, h4, h5, h6, ul, li, a, img, input, button, textarea {
    margin: 0;
    padding: 0;
    overline: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  img {
    border: 0;
  }
  input,
  textarea {
    outline: none;
  }
  button,
  input,
  textarea {
    background: transparent;
    transition: all 0.3s ease-in-out;
    padding: 0;
    font-family: 'Nexa-Regular';
    font-weight: 400;
  }
  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type="number"] {
    -moz-appearance: textfield;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover, 
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: ${COLOR_CONFIG.WHITE} !important;
    -webkit-box-shadow: 0 0 0px 1000px ${COLOR_CONFIG.TRANSPARENT} inset;
    transition: background-color 5000s ease-in-out 0s;
  }
  input, textarea {
    -webkit-user-select: text;
    -khtml-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  button:focus,
  button:active,
  input:focus, 
  input:active {
    outline: none;
  }

  /* Slider */
  .slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-user-select: none;
      -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
  }
  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
  }
  .slick-list:focus {
    outline: none;
  }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand;
  }
  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
      -moz-transform: translate3d(0, 0, 0);
        -ms-transform: translate3d(0, 0, 0);
        -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .slick-track:before,
  .slick-track:after {
    display: table;
    content: '';
  }
  .slick-track:after {
    clear: both;
  }
  .slick-loading .slick-track {
    visibility: hidden;
  }
  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
  }
  [dir='rtl'] .slick-slide {
    float: right;
  }
  .slick-slide img {
    display: block;
  }
  .slick-slide.slick-loading img {
    display: none;
  }
  .slick-slide.dragging img {
    pointer-events: none;
  }
  .slick-initialized .slick-slide {
    display: block;
  }
  .slick-loading .slick-slide {
    visibility: hidden;
  }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
  }
  .slick-arrow.slick-hidden {
    display: none;
  }
  .slick-dots {
    display: flex !important;
    justify-content: center;
    align-items: center;
    height: 8px;
    margin-bottom: 32px;
    li {
      height: 8px;
      button {
        display: block;
        font-size: 0px;
        width: 28px;
        height: 8px;
        border-radius: 4px;
        background: ${COLOR_CONFIG.BLACK};
        margin: 0 4px;
        cursor: pointer;
        &:hover {
          background-color: ${COLOR_CONFIG.YELLOW};
        }
      }
      &.slick-active {
        button {
          background-color: ${COLOR_CONFIG.YELLOW};
        }
      }
    }
  }
  .link-btn {
    width: 165px;
    height: 42px;
    line-height: 42px;
    text-align: center;
    border-radius: 21px;
    background-color: ${COLOR_CONFIG.BLUE};
    font-size: 16px;
    color: ${COLOR_CONFIG.DARK_BLUE};
    font-family: ${FONT_CONFIG.nexaHeavy};
    border: 2px solid ${COLOR_CONFIG.BLUE};
    &:hover {
      background-color: ${COLOR_CONFIG.DARK_BLUE};
      color: ${COLOR_CONFIG.WHITE};
    }
  }
  
`;

export default GlobalStyles;
