// @flow

/* REACT */
import React from 'react';

/* MODULES */

/* CUSTOM MODULES */

/* CONFIGS */
import { MAIN_CONFIG } from './../../../../configs';

/* STYLES */
import {
  Container, Wrapper, Title, CardsList,
  Card, Banner, Main, Name, City, Footer, Street, Address,
} from './styles';

/* TYPES */
import type { Node } from 'react';

// type _t_props = {||};

export default (props: Object): Node => {

  const { title, offices } = props.data;

  return (
    <Container>
      <Wrapper>
        <Title>{title}</Title>
        <CardsList>
          {
            offices.map(i => (
              <Card key={i.id}>
                <Banner src={`${MAIN_CONFIG.CDN_URL}${i.image}`} />
                <Main>
                  <Name>{i.location}</Name>
                  <City>{i.city}</City>
                </Main>
                <Footer>
                  <Street>{i.address_line_1}</Street>
                  <Address>{i.address_line_2}</Address>
                </Footer>
              </Card>
            ))
          }
        </CardsList>
      </Wrapper>
    </Container>
  );
};
