// @flow

/* REACT */
import React from 'react';

/* MODULES */

/* CUSTOM MODULES */

/* CONFIGS */

/* STYLES */
import { LinkOutside, Button } from './styles';

/* TYPES */
import type { Node } from 'react';

type _t_props = {
  isLink: boolean,
  label: string,
  href?: string,
  color?: string,
  margin?: string,
  onClick?: () => void,
};

export default ({
  isLink, label, href, color, margin, onClick,
}: _t_props): Node => isLink ? (
  <LinkOutside
    href={href}
    target="_blank"
    rel="noopener"
    color={color || 'blue'}
    margin={margin || 0}
  >
    {label}
  </LinkOutside>
) : (
  <Button
    onClick={onClick}
    color={color || 'blue'}
    margin={margin || 0}
  >
    {label}
  </Button>
);
