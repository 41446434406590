// @flow

/* REACT */
import React, { useState, useEffect } from 'react';

/* MODULES */
import { Link } from 'react-router-dom';

/* CUSTOM MODULES */
import Header from './../Header';
import Footer from './../Footer';
import Loader from './../Loader';

/* CONFIGS */
import { COLOR_CONFIG } from './../../configs';

/* STYLES */
import {
  PageContainer, Content,
  CookiePopup, CookieText, CookieButton,
} from './styles';

/* TYPES */
import type { Node } from 'react';

type _t_props = {|
  isLoading: boolean,
  children: *,
  bgColor: string,
|};

export default ({ isLoading, bgColor, children }: _t_props): Node => {

  const [isVisibleCookiePopup, setVisibleCookiePopup] = useState<boolean>(true);

  const checkConfirmedCookies = () => {
    const isConfirmed = localStorage.getItem('DRVN-cookie-confirm') || false;
    if (isConfirmed) {
      setVisibleCookiePopup(false);
    }
  };

  useEffect(() => {
    checkConfirmedCookies();
  }, [isVisibleCookiePopup]);

  const confirmCookie = () => {
    localStorage.setItem('DRVN-cookie-confirm', 'true');
    setVisibleCookiePopup(false);
  };

  const declineCookie = () => {
    setVisibleCookiePopup(false);
  };

  return (
    <PageContainer bgColor={bgColor || COLOR_CONFIG.WHITE}>
      <Header />
      {
        isLoading
          ? <Loader />
          : <Content>{children}</Content>
      }
      <Footer />
      {
        isVisibleCookiePopup
          ? (
            <CookiePopup>
              <CookieText>
                This website uses cookies to ensure you get the best experience on our website.
                <Link to="/cookie-policy">Learn more</Link>
              </CookieText>
              <CookieButton onClick={declineCookie}>Decline</CookieButton>
              <CookieButton onClick={confirmCookie}>Accept</CookieButton>
            </CookiePopup>
          )
          : null
      }
    </PageContainer>
  );
};
