// @flow

/* CUSTOM MODULES */
import COLOR_CONFIG from "./color_config";
import MAIN_CONFIG from './main_config';
import IMAGES_CONFIG from './images_config';
import FONT_CONFIG from './font_config';

export {
  COLOR_CONFIG,
  MAIN_CONFIG,
  IMAGES_CONFIG,
  FONT_CONFIG,
};
