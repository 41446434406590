// @flow

/* MODULES */
import styled, { css } from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from '../../configs';

export const Wrapper = styled.div`
  display: flex;
  align-items: stretch;
  padding-top: 85px;
  @media (max-width: 960px) {
    flex-direction: column;
  }
  @media (max-width: 767px) {
    padding-top: 110px;
  }
`;

export const LeftColumn = styled.div`
  background-color: ${COLOR_CONFIG.WHITE};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 37%;
  flex-shrink: 0;
  @media (max-width: 960px) {
    width: 100%;
    display: block;
  }
`;

export const RightColumn = styled.div`
  width: 63%;
  background-color: ${COLOR_CONFIG.DARK_BLUE};
  padding: 110px;
  @media (max-width: 960px) {
    width: 100%;
    margin-bottom: 200px;
    padding: 75px 35px 0;
  }
  @media (max-width: 960px) {
    padding: 45px 35px 0;
    margin-bottom: 150px;
  }
`;

export const TitleWrapper = styled.div`
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: 960px) {
    min-height: 1px;
    padding: 35px;
    justify-content: flex-start;
  }
  @media (max-width: 767px) {
    padding: 15px 35px;
  }
`;

export const Title = styled.p`
  font-size: 78px;
  line-height: 1.2;
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  color: ${COLOR_CONFIG.DARK_BLUE};
  text-align: center;
  align-self: center;
  @media (max-width: 767px) {
    font-size: 48px;
  }
`;

export const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  @media (max-width: 960px) {
    display: none;
  }
  ${props => props.mobile && css`
    display: none;
    @media (max-width: 960px) {
      display: block;
      margin-bottom: -150px;
    }
  `}
`;

export const Img = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Surtitle = styled.p`
  font-size: 36px;
  line-height: 1.2;
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  color: ${COLOR_CONFIG.WHITE};
  margin-bottom: 40px;
`;

export const Text = styled.p`
  color: ${COLOR_CONFIG.WHITE};
  font-size: 24px;
  font-family: ${FONT_CONFIG.nexaBook};
  line-height: 1.3;
  margin-bottom: 30px;
  ${props => props.bold && css`
    font-family: ${FONT_CONFIG.nexaHeavy};
    color: ${COLOR_CONFIG.BLUE};
    text-transform: lowercase;
    margin-bottom: 0;
  `}
`;

export const Content = styled.div`
  color: ${COLOR_CONFIG.WHITE};
  font-size: 18px;
  font-family: ${FONT_CONFIG.nexaBook};
  line-height: 1.4;
  h2 {
    font-size: 36px;
    line-height: 1.2;
    font-family: ${FONT_CONFIG.bebasNeueRegular};
    color: ${COLOR_CONFIG.WHITE};
    margin-bottom: 40px;
  }
  p {
    margin-bottom: 25px;
    strong {
      font-family: ${FONT_CONFIG.nexaHeavy};
      color: ${COLOR_CONFIG.BLUE};
      text-transform: lowercase;
      margin-bottom: 0;
    }
  }
  @media (max-width: 767px) {
    font-size: 16px;
    h2 {
      margin-bottom: 20px;
    }
    p {
      margin-bottom: 15px;
    }
  }
`;
