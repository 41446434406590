// @flow

/* REACT */
import React from 'react';

/* MODULES */

/* CUSTOM MODULES */

/* CONFIGS */
import { MAIN_CONFIG } from './../../../../configs';

/* STYLES */
import {
  Wrapper, BgOverlay, MainInfo, Img, Info, Title, Content,
  List, ListItem, ListHeading, Icon, ItemTitle, ItemText,
} from './styles';

/* TYPES */
import type { Node } from 'react';

type _t_props = {|
  data: Object,
|};

const WhatWeDo = React.forwardRef((props: _t_props, ref): Node => {

  const {
    title, sliders, partials, content
  } = props.data;

  return (
    <Wrapper ref={ref} id="whatWeDo">
      <BgOverlay />
      <MainInfo>
        <Img src={`${MAIN_CONFIG.CDN_URL}${sliders[0]}`} />
        <Info>
          <Title>{title}</Title>
          <Content dangerouslySetInnerHTML={{ __html: content }} />
        </Info>
      </MainInfo>
      <List>
        {
          partials.map(i => (
            <ListItem key={i.id}>
              <ListHeading>
                <ItemTitle>{i.title}</ItemTitle>
                <Icon src={`${MAIN_CONFIG.CDN_URL_ICONS}${i.icon}`} />
              </ListHeading>
              <ItemText>{i.preview}</ItemText>
            </ListItem>
          ))
        }
      </List>
    </Wrapper>
  );
});

export default WhatWeDo;
