// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG } from '../../configs';

export const Footer = styled.footer`
  background-color: ${COLOR_CONFIG.WHITE};
  width: 100%;
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1110px;
  height: 257px;
  margin: 0 auto;
  padding: 43px 15px 65px;
  @media (max-width: 767px) {
    height: auto;
    padding: 70px 35px;
  }
`;

export const Text = styled.p`
  font-size: 14px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  line-height: 1.6;
  margin-bottom: 15px;
`;

export const LinksList = styled.nav`
  margin-bottom: 20px;
  display: flex;
  a {
    display: block;
    font-size: 14px;
    color: ${COLOR_CONFIG.DARK_BLUE};
    line-height: 1.6;
    margin-bottom: 5px;
    &:hover {
      color: ${COLOR_CONFIG.BLUE};
    }
  }
  @media (max-width: 767px) {
    display: block;
  }
`;

export const Column = styled.div`
  margin-right: 50px;
  @media (max-width: 767px) {
    margin: 0 0 35px;
  }
`;

export const SocialList = styled.div`
  display: flex;
  align-items: center;
`;

export const SocialLink = styled.a`
  width: 22px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLOR_CONFIG.DARK_BLUE};
  margin-right: 16px;
`;

export const SocialIcon = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;

export const LinkOutside = styled.a`
  font-size: 14px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  line-height: 1.6;
  margin-bottom: 5px;
  &:hover {
    color: ${COLOR_CONFIG.BLUE};
  }
`;
