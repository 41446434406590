// @flow

/* REACT */
import React from 'react';

/* MODULES */

/* CUSTOM MODULES */

/* CONFIGS */
import { MAIN_CONFIG } from '../../../../configs';

/* STYLES */
import {
  Wrapper, MainInfo, Title, Content, ImgWrapper, Img,
} from './styles';

/* TYPES */
import type { Node } from 'react';

type _t_props = {|
  data: Object,
|};

const WhyWeDoIt = React.forwardRef((props: _t_props, ref): Node => {

  const { title, content, image } = props.data;

  return (
    <Wrapper ref={ref} id="whyWeDoIt">
      <MainInfo>
        <Title>{title}</Title>
        <Content dangerouslySetInnerHTML={{ __html: content }} />
      </MainInfo>
      <ImgWrapper>
        <Img src={`${MAIN_CONFIG.CDN_URL}${image}`} />
      </ImgWrapper>
    </Wrapper>
  );
});

export default WhyWeDoIt;
