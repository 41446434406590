// @flow

/* REACT */
import React from 'react';

/* MODULES */
import { Link } from "react-router-dom";

/* CUSTOM MODULES */

/* CONFIGS */
import { IMAGES_CONFIG } from './../../configs';

/* STYLES */
import {
  Footer, Wrapper, Text, LinksList, Column,
  SocialList, SocialLink, SocialIcon, LinkOutside,
} from './styles';

/* TYPES */
import type { Node } from 'react';

// type _t_props = {||};

const linksList = [
  [
    {
      id: 1,
      label: 'Accessibility Statement',
      url: '',
      to: '/accessibility',
    },
    {
      id: 2,
      label: 'Privacy Policy',
      url: '',
      to: '/privacy-policy',
    },
    {
      id: 3,
      label: 'Cookie Policy',
      url: '',
      to: '/cookie-policy',
    },
  ],
  [
    {
      id: 4,
      label: 'Disclaimer',
      url: '',
      to: '/disclaimer',
    },
    {
      id: 5,
      label: 'Terms & Conditions',
      url: '',
      to: '/terms-conditions',
    },
    {
      id: 6,
      label: 'Acceptable Use Policy',
      url: '',
      to: '/acceptable-use-policy',
    },
  ],
  [
    {
      id: 7,
      label: 'TCC Global',
      url: 'https://tccglobal.com/',
      to: '',
    },
    {
      id: 8,
      label: 'Summit Media',
      url: 'https://www.summit.co.uk/',
      to: '',
    },
  ],
  [
    {
      id: 9,
      label: 'Contact Us',
      url: '',
      to: '/contact-us',
    },
    {
      id: 10,
      label: 'Join Us',
      url: 'https://drivvn.recruitee.com/',
      to: '',
    },
    // {
    //   id: 11,
    //   label: 'Latest News',
    //   url: '',
    //   to: '',
    // },
  ],
];

export default (): Node => (
  <Footer>
    <Wrapper>
      <Text>{`Copyright ${new Date().getFullYear()} drivvn Limited. All rights reserved.`}</Text>
      <LinksList>
        {
          linksList.map((link, idx) => (
            <Column key={idx}>
              {
                link.map(i => i.to ? (
                  <Link key={i.id} to={i.to}>{i.label}</Link>
                ) : (
                  <LinkOutside key={i.id} href={i.url} target="_blank" rel="noopener">{i.label}</LinkOutside>
                ))
              }
            </Column>
          ))
        }
      </LinksList>
      <SocialList>
        <SocialLink href="https://www.linkedin.com/company/drivvn/" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={IMAGES_CONFIG.linkedinIcon} />
        </SocialLink>
        {/* <SocialLink href="https://twitter.com/drivvn" target="_blank" rel="noopener noreferrer">
          <SocialIcon src={IMAGES_CONFIG.twitterIcon} />
        </SocialLink> */}
      </SocialList>
    </Wrapper>
  </Footer>
);
