// @flow

export default {
  nexaHeavy: 'Nexa-Heavy',
  nexaRegular: 'Nexa-Regular',
  nexaBook: 'Nexa-Book',
  nexaBold: 'NexaBold',
  nexaLight: 'Conv_NexaLight',
  nexaBlack: 'Conv_NexaBlack',
  nexaHeavyItalic: 'Conv_NexaHeavyItalic',
  nexaBookItalic: 'Conv_NexaBookItalic',
  bebasNeueBook: 'BebasNeueBook',
  bebasNeueRegular: 'BebasNeueRegular',
};
