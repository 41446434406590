// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from "./../../../../configs";

export const Wrapper = styled.div`
  width: 100%;
  background-color: ${COLOR_CONFIG.GRAY_LIGHT};
  display: flex;
  margin-bottom: 106px;
  @media (max-width: 960px) {
    flex-direction: column;
    margin-bottom: 80px;
    background-color: ${COLOR_CONFIG.TRANSPARENT};
  }
`;

export const MainInfo = styled.div`
  max-width: 495px;
  margin-right: 75px;
  margin-left: auto;
  padding: 60px 0;
  @media (max-width: 1200px) {
    padding: 60px 15px;
  }
  @media (max-width: 960px) {
    width: 100%;
    max-width: 100%;
    margin: 0;
    background-color: ${COLOR_CONFIG.GRAY_LIGHT};
    padding: 60px 15px 200px;
  }
  @media (max-width: 767px) {
    padding: 63px 35px 200px;
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 78px;
  line-height: 1.2;
  color: ${COLOR_CONFIG.DARK_BLUE};
  margin-bottom: 32px;
  position: relative;
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  @media (max-width: 767px) {
    font-size: 48px;
    margin-bottom: 25px;
  }
`;

export const Content = styled.div`
  font-size: 18px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  line-height: 1.3;
  margin-bottom: 20px;
  font-family: ${FONT_CONFIG.nexaBook};
  @media (max-width: 767px) {
    font-size: 16px;
    margin-bottom: 15px;
  }
`;

export const ImgWrapper = styled.div`
  width: 50%;
  flex-shrink: 0;
  @media (max-width: 960px) {
    margin: -150px auto 0;
  }
  @media (max-width: 767px) {
    margin: -150px auto 0;
    width: calc(100% - 70px);
  }
`;

export const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;
