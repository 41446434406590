// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
// import { COLOR_CONFIG, FONT_CONFIG } from '../../configs';

export const Wrapper = styled.div`
  width: 100%;
  padding-top: 85px;
  height: calc(100vh - 257px);
  display: flex;
  align-items: center;
  justify-content: center;
  svg {
    width: 100px;
    height: 100px;
  }
`;
