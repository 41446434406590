// @flow

/* REACT */
import React from 'react';

import { Link } from 'react-router-dom';

/* CONFIGS */
import { IMAGES_CONFIG } from './../../../../configs';

/* STYLES */
import {
  Hero, HeroImg, Content, Text, Logo,
} from './styles';

type _t_props = {|
  data: Object,
|};

export default (data: _t_props) => {
  const { title } = data.data;
  return (
    <Hero>
      <HeroImg src={IMAGES_CONFIG.heroBg} />
      <Content>
        <Logo src={IMAGES_CONFIG.logoBlue} />
        <Text>{title}</Text>
        <Link to="/our-belief" className="link-btn">Read More</Link>
      </Content>
    </Hero>
  );
};
