// @flow

/* REACT */
import React, { useCallback, useState } from 'react';

/* MODULES */
import { NavLink, useLocation } from 'react-router-dom';
import { Link } from 'react-scroll';

/* CUSTOM MODULES */

/* CONFIGS */
import { IMAGES_CONFIG } from '../../configs';

/* STYLES */
import {
  Header, Nav, Logo, ToggleBtn, ToggleBtnLine, LinkOutside, Btn, CloseNavBtn, Img,
  LinkWrapper,
} from './styles';

/* TYPES */
import type { Node } from 'react';

// type _t_props = {||};

const navListData = [
  {
    id: 'whatWeDo',
    to: '/?to=whatWeDo',
    label: 'What we do',
  },
  {
    id: 'howWeDoIt',
    to: '/?to=howWeDoIt',
    label: 'How we do it',
  },
  {
    id: 'whyWeDoIt',
    to: '/?to=whyWeDoIt',
    label: 'Why we do it',
  },
  {
    id: 'clients',
    to: '/?to=clients',
    label: 'Clients',
  },
  {
    id: 'aboutUs',
    to: '/?to=aboutUs',
    label: 'About Us',
  },
];

export default (): Node => {
  const [isOpenMenu, setOpenMenu] = useState<boolean>(false);
  const { pathname } = useLocation();
  const isHomePage = pathname === '/';

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  };

  const toggleMenu = useCallback(() => {
    setOpenMenu(true);
    document.body.classList.add("hidden");
  }, []);

  const closeMenu = useCallback(() => {
    setOpenMenu(false);
    document.body.classList.remove("hidden");
  }, []);

  return (
    <Header>
      {
        isHomePage ? (
          <Btn onClick={scrollToTop}>
            <Logo src={IMAGES_CONFIG.logo} alt="drivvn" />
          </Btn>
        ) : (
          <NavLink to="/">
            <Logo src={IMAGES_CONFIG.logo} alt="drivvn" />
          </NavLink>
        )
      }
      <Nav isOpenMenu={isOpenMenu}>
        <CloseNavBtn onClick={closeMenu}>
          <Img src={IMAGES_CONFIG.iconClose} />
        </CloseNavBtn>
        {
          isHomePage ? (
            navListData.map(link => (
              <LinkWrapper key={link.id} onClick={closeMenu}>
                <Link
                  to={link.id}
                  smooth
                  offset={-120}
                  duration={400}
                >
                  {link.label}
                </Link>
              </LinkWrapper>
            ))
          ) : (
            navListData.map(link => (
              <LinkWrapper key={link.id} onClick={closeMenu}>
                <NavLink to={link.to}>
                  {link.label}
                </NavLink>
              </LinkWrapper>
            ))
          )
        }
        <LinkWrapper onClick={closeMenu}>
          <NavLink to="/contact-us">Contact Us</NavLink>
        </LinkWrapper>
        <LinkWrapper onClick={closeMenu}>
          <LinkOutside href="https://drivvn.recruitee.com/" target="_blank" rel="noopener noreferrer">Join Us</LinkOutside>
        </LinkWrapper>
      </Nav>
      <ToggleBtn onClick={toggleMenu}>
        <ToggleBtnLine />
        <ToggleBtnLine />
        <ToggleBtnLine last />
      </ToggleBtn>
    </Header>
  );
};
