// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from "./../../configs";

export const BgWrapper = styled.div`
  margin-top: 85px;
  background-color: ${COLOR_CONFIG.DARK_BLUE};
  @media (max-width: 767px) {
    margin-top: 80px;
  }
`;

export const Wrapper = styled.div`
  padding: 176px 15px 240px;
  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding: 65px 35px 210px;
  }
`;

export const Title = styled.p`
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  font-size: 78px;
  line-height: 1.1;
  margin-bottom: 52px;
  color: ${COLOR_CONFIG.WHITE};
  max-width:350px;
  @media (max-width: 767px) {
    font-size: 48px;
    max-width: 120px;
    margin-bottom: 0;
  }
`;

export const NewsList = styled.div`
  max-width: 1080px;
  width: 100%;
  margin: -140px auto 0;
  background-color: ${COLOR_CONFIG.WHITE};
  padding-bottom: 80px;
  @media (max-width: 1100px) {
    padding: 0 15px;
  }
  @media (max-width: 1100px) {
    padding: 0 35px;
    background-color: ${COLOR_CONFIG.TRANSPARENT};
  }
`;

export const List = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const NewsItem = styled.div`
  max-width: calc((100% - 60px) / 3);
  margin-bottom: 80px;
  overflow: hidden;
  @media (max-width: 1100px) {
    max-width: calc((100% - 30px) / 2);
    margin-bottom: 50px;
  }
  @media (max-width: 767px) {
    max-width: 100%;
  }
`;

export const BannerWrapper = styled.div`
  width: 100%;
  height: 280px;
  overflow: hidden;
  border-bottom: 16px solid ${COLOR_CONFIG.DARK_BLUE};
`;

export const Banner = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Heading = styled.div`
  display: flex;
  padding: 21px 0 11px;
`;

export const Text = styled.p`
  font-family: ${FONT_CONFIG.nexaBook};
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-size: 18px;
  line-height: 22px;
`;

export const NewsTitle = styled.p`
  font-family: ${FONT_CONFIG.nexaHeavy};
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-size: 24px;
  line-height: 28px;
  margin-bottom: 15px;
  max-height: 55px;
  overflow: hidden;
`;

export const NewsPreview = styled.p`
  font-family: ${FONT_CONFIG.nexaBook};
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 20px;
  max-height: 72px;
  overflow: hidden;
  & + a {
    display: flex;
    align-items: center;
    color: ${COLOR_CONFIG.DARK_BLUE};
    font-family: ${FONT_CONFIG.nexaHeavy};
    font-size: 18px;
    padding-top: 3px;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const IconWrapper = styled.span`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: ${COLOR_CONFIG.BLUE};
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 13px;
  margin-top: -3px;
`;

export const Icon = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
  transform: rotate(180deg);
`;

export const Btn = styled.button`
  display: block;
  padding-top: 3px;
  width: 165px;
  height: 42px;
  border-radius: 21px;
  background-color: ${COLOR_CONFIG.BLUE};
  font-size: 16px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-family: ${FONT_CONFIG.nexaHeavy};
  border: 2px solid ${COLOR_CONFIG.BLUE};
  margin: 0 auto;
  &:hover {
    background-color: ${COLOR_CONFIG.DARK_BLUE};
    color: ${COLOR_CONFIG.WHITE};
  }
`;
