// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from "./../../../../configs";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1246px;
  height: auto;
  padding: 43px 15px 0;
  margin: 0 auto 127px;
  position: relative;
`;

export const BgOverlay = styled.div`
  position: absolute;
  left: 15px;
  top: 43px;
  width: calc(100% - 30px);
  height: calc(100% - 43px - 53px);
  background-color: ${COLOR_CONFIG.GRAY_LIGHT};
  border-radius: 0 100px 100px 0;
  @media (max-width: 960px) {
    left: 0;
    width: calc(100% - 11px);
    height: 40%;
  }
`;

export const MainInfo = styled.div`
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-bottom: 43px;
  @media (max-width: 960px) {
    display: block;
  }
`;

export const Img = styled.img`
  flex: 0 0 49%;
  display: block;
  max-width: 49%;
  width: 49%;
  height: auto;
  margin-top: -43px;
  margin-right: 80px;
  @media (max-width: 767px) {
    display: none;
  }
`;

export const Info = styled.div`
  padding-top: 62px;
  padding-right: 50px;
  @media (max-width: 767px) {
    padding: 24px 26px 0 19px;
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 78px;
  line-height: 1.2;
  color: ${COLOR_CONFIG.DARK_BLUE};
  margin-bottom: 13px;
  position: relative;
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  padding-top: 53px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 130px;
    height: 18px;
    background-color: ${COLOR_CONFIG.DARK_BLUE};
  }
  @media (max-width: 767px) {
    font-size: 48px;
    padding-top: 34px;
    &:before {
      width: 80px;
      height: 16px;
    }
  }
`;

export const Content = styled.div`
  font-size: 18px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  line-height: 1.3;
  margin-bottom: 43px;
  font-family: ${FONT_CONFIG.nexaBook};
  padding-bottom: 53px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 130px;
    height: 18px;
    background-color: ${COLOR_CONFIG.BLUE};
  }
  @media (max-width: 767px) {
    font-size: 16px;
    margin-bottom: 0;
    padding-bottom: 50px;
    &:after {
      width: 80px;
      height: 16px;
    }
  }
`;

export const Text = styled.p`
  font-size: 20px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  line-height: 1.2;
  margin-bottom: 43px;
  font-family: ${FONT_CONFIG.nexaBook};
  padding-bottom: 53px;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 130px;
    height: 18px;
    background-color: ${COLOR_CONFIG.BLUE};
  }
`;

export const Button = styled.button`
  padding-top: 3px;
  margin: 0 auto 0 0;
  width: 165px;
  height: 42px;
  border-radius: 21px;
  background-color: ${COLOR_CONFIG.BLUE};
  font-size: 16px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-family: ${FONT_CONFIG.nexaHeavy};
  &:hover {
    background-color: ${COLOR_CONFIG.DARK_BLUE};
    color: ${COLOR_CONFIG.BLUE};
  }
`;

export const List = styled.div`
  display: flex;
  justify-content: center;
  align-items: stretch;
  position: relative;
  @media (max-width: 960px) {
    flex-direction: column;
    align-items: center;
    box-shadow: 0 4px 10px rgba(98, 97, 98, 0.15);
    max-width: 340px;
    width: 100%;
    background-color: ${COLOR_CONFIG.WHITE};
    margin: 0 auto;
  }
`;

export const ListItem = styled.div`
  max-width: 358px;
  background-color: ${COLOR_CONFIG.WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 8px;
  padding: 27px 38px 30px; 
  box-shadow: 0 4px 10px rgba(98, 97, 98, 0.15);
  @media (max-width: 960px) {
    width: 100%;
    box-shadow: none;
    margin: 0;
    padding: 35px 25px;
  }
`;

export const ListHeading = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 18px;
  height: 56px;
  overflow: hidden;
  @media (max-width: 767px) {
    margin-bottom: -5px;
  }
`;

export const Icon = styled.img`
  display: flex;
  max-width: 42px;
  height: auto;
`;

export const ItemTitle = styled.p`
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-size: 20px;
  line-height: 1.4;
  font-family: ${FONT_CONFIG.nexaHeavy};
  padding-right: 20px;
  @media (max-width: 767px) {
    font-size: 18px;
  }
`;

export const ItemText = styled.p`
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-size: 18px;
  line-height: 1.45;
  font-family: ${FONT_CONFIG.nexaBook};
  @media (max-width: 767px) {
    font-size: 16px;
    padding-right: 65px;
  }
`;
