// @flow

/* MODULES */
import styled, { css } from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from '../../configs';

export const Wrapper = styled.div`
  max-width: 840px;
  margin: 0 auto;
  padding: 145px 15px 100px 15px;
  @media (max-width: 767px) {
    padding: 150px 35px 70px;
  }
`;

export const Title = styled.p`
  font-size: 78px;
  line-height: 1.2;
  color: ${COLOR_CONFIG.WHITE};
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  margin-bottom: 33px;
  position: relative;
  padding-top: 48px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-50%);
    width: 130px;
    height: 18px;
    background-color: ${COLOR_CONFIG.BLUE};
  }
  @media (max-width: 960px) {
    &:after {
      transform: translateX(0);
    }
  }
  @media (max-width: 767px) {
    font-size: 48px;
    padding-top: 45px;
    margin-bottom: 15px;
    &:after {
      width: 80px;
      height: 16px;
    }
  }
`;

export const Form = styled.form``;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 21px;
  & > div {
    width: 100%;
    max-width: calc((100% - 40px) / 2);
  }
  & > p {
    width: 100%;
    max-width: calc((100% - 40px) / 2);
    &:first-of-type {
      margin-right: 40px;
    }
  }
  & > input {
    max-width: calc((100% - 40px) / 2);
    margin-bottom: 0;
    &:first-of-type {
      margin-right: 40px;
    }
  }
  @media (max-width: 767px) {
    display: block;
    margin-bottom: 0;
    & > div {
      width: 100%;
      max-width: 100%;
    }
    & > input {
      max-width: 100%;
      margin-bottom: 16px;
      &:first-of-type {
        margin-right: 0;
      }
    }
  }
`;

export const Text = styled.p`
  font-size: 18px;
  line-height: 22px;
  color: ${COLOR_CONFIG.WHITE};
  font-family: ${FONT_CONFIG.nexaBold};
  ${props => props.bold && css`
    color: ${COLOR_CONFIG.BLUE};
    font-size: 14px;
    line-height: 20px;
    padding: 10px 0 20px;
  `}
`;

export const SelectWrapper = styled.div`
  position: relative;
`;

export const Selected = styled.button`
  width: 100%;
  height: 38px;
  border: 1px solid ${COLOR_CONFIG.WHITE};
  padding: 0 20px;
  color: ${COLOR_CONFIG.WHITE};
  font-family: ${FONT_CONFIG.nexaBook};
  font-size: 18px;
  line-height: 38px;
  text-align: left;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 11px 10px 0 10px;
    border-color: #fff transparent transparent transparent;
    transition: all 0.3s ease-in-out;
  }
  ${props => props.isOpened && css`
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  `}
`;

export const SelectList = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  transform: translateY(100%);
  border: 1px solid ${COLOR_CONFIG.WHITE};
  border-top: none;
  padding: 7px 0;
  background-color: ${COLOR_CONFIG.DARK_BLUE};
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s ease-in-out;
  ${props => props.isVisible && css`
    opacity: 1;
    pointer-events: auto;
  `}
`;

export const SelectItem = styled.p`
  color: ${props => props.isSelected ? COLOR_CONFIG.BLUE : COLOR_CONFIG.WHITE};
  padding: 7px 20px;
  font-family: ${FONT_CONFIG.nexaBook};
  font-size: 18px;
  cursor: pointer;
`;

export const Input = styled.input`
  border: 1px solid ${COLOR_CONFIG.WHITE};
  width: 100%;
  height: 38px;
  margin-bottom: 21px;
  padding: 0 20px;
  color: ${COLOR_CONFIG.WHITE};
  font-family: ${FONT_CONFIG.nexaBook};
  font-size: 18px;
  padding-top: 3px;
  &:focus {
    border-color: ${COLOR_CONFIG.BLUE};
  }
  @media (max-width: 767px) {
    margin-bottom: 16px;
  }
`;

export const Message = styled.textarea`
  border: 1px solid ${COLOR_CONFIG.WHITE};
  width: 100%;
  height: 113px;
  margin-bottom: 25px;
  padding: 10px 20px;
  color: ${COLOR_CONFIG.WHITE};
  font-family: ${FONT_CONFIG.nexaBook};
  font-size: 18px;
  resize: none;
  &:focus {
    border-color: ${COLOR_CONFIG.BLUE};
  }
`;

export const Hint = styled.div`
  color: ${COLOR_CONFIG.WHITE};
  font-size: 18px;
  line-height: 1.3;
  font-family: ${FONT_CONFIG.nexaBook};
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
`;

export const HintBtn = styled.button`
  margin-left: 8px;
`;

export const Img = styled.img``;

export const HintDescription = styled.div`
  color: ${COLOR_CONFIG.WHITE};
  font-size: 14px;
  line-height: 1.4;
  font-family: ${FONT_CONFIG.nexaBook};
  margin-bottom: 25px;
  a {
    color: ${COLOR_CONFIG.WHITE};
    text-decoration: underline;
    padding: 0 4px;
    &:hover {
      color: ${COLOR_CONFIG.BLUE};
    }
  }
`;

export const BtnSubmit = styled.button`
  padding-top: 3px;
  width: 165px;
  height: 42px;
  border-radius: 21px;
  background-color: ${COLOR_CONFIG.BLUE};
  font-size: 16px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-family: ${FONT_CONFIG.nexaHeavy};
  border: 2px solid ${COLOR_CONFIG.BLUE};
  &:hover {
    background-color: ${COLOR_CONFIG.DARK_BLUE};
    color: ${COLOR_CONFIG.WHITE};
  }
  &:hover {
    color: ${COLOR_CONFIG.WHITE};
    background-color: ${COLOR_CONFIG.DARK_BLUE};
  }
  ${props => props.disabled && css`
    color: rgba(15, 26, 61, 0.29);
    background-color: rgba(126, 224, 241, 0.3);
    pointer-events: none;
    border-color: ${COLOR_CONFIG.TRANSPARENT};
  `}
`;

export const SuccessMessage = styled.div`
  width: 100%;
  background-color: ${COLOR_CONFIG.BLUE};
  position: absolute;
  left: 0;
  bottom: 0;
  & > div {
    padding: 0 15px;
    height: 58px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 767px) {
      padding: 0 15px 0 25px;
    }
  }
`;

export const TextSuccess = styled.p`
  font-family: ${FONT_CONFIG.nexaBold};
  font-size: 20px;
  line-height: 1.2;
  color: ${COLOR_CONFIG.DARK_BLUE};
  @media (max-width: 767px) {
    font-size: 16px;
  }
`;

export const BtnClose = styled.button``;

export const CheckboxWrapper = styled.label`
  cursor: pointer;
  display: flex;
  margin-bottom: 21px;
`;

export const CheckIcon = styled.span`
  width: 18px;
  height: 18px;
  background-color: ${COLOR_CONFIG.DARK_BLUE};
  border: 1px solid ${COLOR_CONFIG.WHITE};
  flex-shrink: 0;
  margin-right: 16px;
  transition: all 0.3s ease-in-out;
  ${props => props.isSelected && css`
    background-color: ${COLOR_CONFIG.BLUE};
  `}
`;

export const CheckLabel = styled.span`
  color: ${COLOR_CONFIG.WHITE};
  font-size: 16px;
  line-height: 1.4;
  font-family: ${FONT_CONFIG.nexaBook};
  a {
    color: ${COLOR_CONFIG.WHITE};
    text-decoration: underline;
    padding: 0 4px;
    &:hover {
      color: ${COLOR_CONFIG.BLUE};
    }
    &:last-of-type {
      padding-right: 0;
    }
  }
`;
