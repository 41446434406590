// @flow

/* REACT */
import React, { useState, useEffect } from 'react';

/* MODULES */
import { Link } from 'react-router-dom';
import moment from 'moment';

/* CUSTOM MODULES */
import { PageContainer } from './../../components';

/* CONFIGS */
import { MAIN_CONFIG, COLOR_CONFIG, IMAGES_CONFIG } from './../../configs';

/* STYLES */
import {
  BgWrapper, Wrapper, Title, NewsList, List, Btn, BannerWrapper,
  NewsItem, Banner, Heading, NewsTitle, NewsPreview, Text,
  Icon, IconWrapper,
} from './styles';

/* TYPES */
import type { Node } from 'react';

// type _t_props = {||};

export default (): Node => {
  const [data, setData] = useState<Object>(null);

  const getData = async () => {
    try {
      const response = await fetch(`${MAIN_CONFIG.API_URL}news`);
      const dataResp = await response.json();
      setData(dataResp.data);
    } catch (e) {
      console.warn('GET DATA NEWS PAGE ERROR', e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  return (
    <PageContainer isLoading={!data} bgColor={COLOR_CONFIG.WHITE}>
      <BgWrapper>
        <Wrapper>
          <Title>{data?.title || 'News & insights'}</Title>
        </Wrapper>
      </BgWrapper>
      <NewsList>
        <List>
          {
            data && data.length && data.map(i => (
              <NewsItem key={i.title}>
                <BannerWrapper>
                  <Banner src={`${MAIN_CONFIG.CDN_URL}${i.image}`} />
                </BannerWrapper>
                <Heading>
                  <Text>{moment(i?.published_at || '').format('DD MMM')}</Text>
                </Heading>
                <NewsTitle>{i.title}</NewsTitle>
                <NewsPreview>{i.preview.length > 80 ? `${i.preview.substring(0, 86)} ...` : i.preview}</NewsPreview>
                <Link to={`/news/${i.slug}`}>
                  <IconWrapper>
                    <Icon src={IMAGES_CONFIG.iconArrowLeft} />
                  </IconWrapper>
                  Read more
                </Link>
              </NewsItem>
            ))
          }
        </List>
        <Btn>Load More</Btn>
      </NewsList>
    </PageContainer>
  );
};
