// @flow

/* REACT */
import React from 'react';

/* MODULES */
import {
  BrowserRouter as Router, Switch, Route, useLocation,
} from "react-router-dom";
import ReactGA from 'react-ga';

/* CUSTOM MODULES */
import {
  Home, TermsConditions, ErrorPage, CorporateResponsibility, PrivacyPolicy,
  // Hold,
  ContactUs, CookiePolicy, Accessibility, OurBelief, Disclaimer,
  AcceptableUsePolicy, SingleNews
} from './containers';

/* CONFIGS */

/* STYLES */
import GlobalStyles from './styles/global';

/* TYPES */
import type { Node } from 'react';

ReactGA.initialize('UA-173195459-1', {
  debug: false,
  titleCase: true,
  gaOptions: {
    siteSpeedSampleRate: 100
  }
});

function usePageViews() {
  const location = useLocation();
  const isCookieConfirmed = localStorage.getItem('DRVN-cookie-confirm') || false;
  React.useEffect(() => {
    if (isCookieConfirmed) {
      ReactGA.pageview(location.pathname);
    }
  }, [location, isCookieConfirmed]);
}

function AppRender() {
  usePageViews();
  return (
    <Switch>
      <Route path="/terms-conditions"><TermsConditions /></Route>
      <Route path="/corporate-responsibility"><CorporateResponsibility /></Route>
      <Route path="/privacy-policy"><PrivacyPolicy /></Route>
      <Route path="/cookie-policy"><CookiePolicy /></Route>
      <Route path="/accessibility"><Accessibility /></Route>
      <Route path="/disclaimer"><Disclaimer /></Route>
      <Route path="/acceptable-use-policy"><AcceptableUsePolicy /></Route>
      <Route path="/our-belief"><OurBelief /></Route>
      <Route path="/contact-us"><ContactUs /></Route>
      <Route path="/news/:id"><SingleNews /></Route>
      {/* <Route exact path="/news"><News /></Route> */}
      <Route path="/404"><ErrorPage /></Route>
      <Route exact path="/"><Home /></Route>
      <Route path="*"><ErrorPage /></Route>
    </Switch>
  );
}

export default (): Node => (
  <Router>
    <GlobalStyles />
    <AppRender />
  </Router>
);
