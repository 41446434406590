// @flow

/* REACT */
import React, { useRef } from 'react';

/* MODULES */
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import { MobileView, BrowserView } from "react-device-detect";

/* CUSTOM MODULES */

/* CONFIGS */
import { MAIN_CONFIG, IMAGES_CONFIG } from './../../../../configs';

/* STYLES */
import {
  Container, Wrapper, Title, Content, DecorLine,
  // ContactForm, FormTitle, FormRow, InputWrapper, Input, FormDescription,
  // FormSubmit, BtnIcon,
  NewsSlider, SlideItem, Banner, SlideBgOverlay, SlideTitle,
  SliderBtnNext, SliderBtnIcon, SliderBtnPrev,
  NewsList,
} from './styles';

/* TYPES */
import type { Node } from 'react';

// type _t_props = {||};

const News = React.forwardRef((props: Object, ref): Node => {
  const sliderRef = useRef(null);

  const { title, news } = props.data;
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 2000,
  };

  const sliderPrev = () => sliderRef?.current?.slickPrev();

  const sliderNext = () => sliderRef?.current?.slickNext();

  return (
    <Container ref={ref} id="news">
      <Wrapper>
        <Title>{title}</Title>
      </Wrapper>
      <Content>
        {/* <ContactForm>
          <FormTitle>Sign up to our drivvn newsletter for the regular news and insights.</FormTitle>
          <FormRow>
            <InputWrapper>
              <Input
                placeholder="your first name"
                value={firstName}
                onChange={e => setFirstName(e.target.value)}
              />
            </InputWrapper>
            <InputWrapper>
              <Input
                placeholder="your last name"
                value={lastName}
                onChange={e => setLastName(e.target.value)}
              />
            </InputWrapper>
          </FormRow>
          <InputWrapper>
            <Input
              placeholder="your email address "
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </InputWrapper>
          <FormDescription>By signing up you are opting in to receive marketing communications from tcc global.</FormDescription>
          <FormSubmit>
            Send
            <BtnIcon src={IMAGES_CONFIG.iconSend} />
          </FormSubmit>
        </ContactForm> */}
        <MobileView>
          <NewsList>
            {
                news.map(i => (
                  <SlideItem key={i.id}>
                    <Link to={`/news/${i.slug}`}>
                      <Banner src={`${MAIN_CONFIG.CDN_URL}${i.preview_image}`} />
                      <SlideBgOverlay />
                      <SlideTitle>{i.title}</SlideTitle>
                    </Link>
                  </SlideItem>
                ))
              }
          </NewsList>
        </MobileView>
        <BrowserView>
          <NewsSlider>
            <SliderBtnPrev onClick={sliderPrev}>
              <SliderBtnIcon src={IMAGES_CONFIG.iconArrowRight} />
            </SliderBtnPrev>
            <Slider ref={sliderRef} {...sliderSettings}>
              {
                news.map(i => (
                  <SlideItem key={i.id}>
                    <Link to={`/news/${i.slug}`}>
                      <Banner src={`${MAIN_CONFIG.CDN_URL}${i.preview_image}`} />
                      <SlideBgOverlay />
                      <SlideTitle>{i.title}</SlideTitle>
                    </Link>
                  </SlideItem>
                ))
              }
            </Slider>
            <SliderBtnNext onClick={sliderNext}>
              <SliderBtnIcon src={IMAGES_CONFIG.iconArrowRight} />
            </SliderBtnNext>
          </NewsSlider>
        </BrowserView>
      </Content>
      <Wrapper>
        <DecorLine />
      </Wrapper>
    </Container>
  );
});

export default News;
