// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from "./../../configs";

export const Wrapper = styled.div`
  min-height: 100vh;
  height: 100%;
  width: 100%;
  background-image: ${props => `url(${props.bg})`};
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const Header = styled.div`
  position: absolute;
  top: 38px;
  left: 43px;
  display: flex;
  align-items: center;
`;

export const LogoBtn = styled.button``;

export const Logo = styled.img``;

export const Form = styled.form``;

export const Input = styled.input`
  width: 229px;
  height: 38px;
  border: 1px solid ${COLOR_CONFIG.WHITE};
  padding: 0 20px;
  margin: 0 28px;
  color: ${COLOR_CONFIG.WHITE};
  font-size: 20px;
  line-height: 24px;
  font-family: ${FONT_CONFIG.nexaLight};
`;

export const Btn = styled.button`
  padding-top: 3px;
  margin: 0 auto 0 0;
  width: 165px;
  height: 42px;
  border-radius: 21px;
  background-color: ${COLOR_CONFIG.BLUE};
  font-size: 16px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-family: ${FONT_CONFIG.nexaHeavy};
  &:hover {
    background-color: ${COLOR_CONFIG.DARK_BLUE};
    color: ${COLOR_CONFIG.BLUE};
  }
`;

export const LogoText = styled.img`
  width: 487px;
  max-width: 90vw;
  height: auto;
`;
