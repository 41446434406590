// @flow

/* REACT */
import React, { useState } from 'react';

/* MODULES */

/* CUSTOM MODULES */

/* CONFIGS */
import { MAIN_CONFIG } from './../../../../configs';

/* STYLES */
import {
  Wrapper, SliderWrapper, Main, Title, Content, Button, Img,
} from './styles';

/* TYPES */
import type { Node } from 'react';

type _t_props = {|
  data: Object,
|};

const HowWeDoIt = React.forwardRef((props: _t_props, ref): Node => {
  const [isCollapsed, setCollapsed] = useState<boolean>(true);
  const { title, content, sliders } = props.data;

  return (
    <Wrapper ref={ref} id="howWeDoIt">
      <SliderWrapper>
        <Img src={`${MAIN_CONFIG.CDN_URL}${sliders[0]}`} />
      </SliderWrapper>
      <Main>
        <Title>{title}</Title>
        <Content dangerouslySetInnerHTML={{ __html: content }} isCollapsed={isCollapsed} />
        <Button onClick={() => setCollapsed(!isCollapsed)}>{isCollapsed ? 'Read More' : 'Read Less'}</Button>
      </Main>
    </Wrapper>
  );
});

export default HowWeDoIt;
