// @flow

/* REACT */
import React from 'react';

/* MODULES */
import { Link } from "react-router-dom";

/* CUSTOM MODULES */
import { PageContainer } from './../../components';

/* CONFIGS */
import { IMAGES_CONFIG } from './../../configs';

/* STYLES */
import {
  ImgBg, Wrapper, Title, Text,
} from './styles';

/* TYPES */
import type { Node } from 'react';

// type _t_props = {||};

export default (): Node => (
  <PageContainer>
    <ImgBg src={IMAGES_CONFIG.heroBg} />
    <Wrapper>
      <Title>Sorry this PAGE is not available</Title>
      <Text>The link you have followed may be broken or the page you are looking for may have been removed.</Text>
      <Link to="/" className="link-btn">Return Home</Link>
    </Wrapper>
  </PageContainer>
);
