// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from "./../../configs";

export const Wrapper = styled.div`
  padding: 184px 15px 57px;
  max-width: 760px;
  width: 100%;
  margin: 0 auto;
  @media (max-width: 767px) {
    padding: 180px 35px 70px;
  }
`;

export const Title = styled.p`
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  font-size: 48px;
  line-height: 1.1;
  margin-bottom: 52px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  @media (max-width: 767px) {
    font-size: 36px;
    margin-bottom: 30px;
  }
`;

export const Content = styled.div`
  p {
    font-size: 18px;
    line-height: 28px;
    color: ${COLOR_CONFIG.DARK_BLUE};
    font-family: ${FONT_CONFIG.nexaBook};
    margin-bottom: 20px;
    &::last-of-type {
      margin-bottom: 0;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      margin-bottom: 15px;
    }
  }
  h2 {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 15px;
    font-family: ${FONT_CONFIG.nexaHeavy};
    color: ${COLOR_CONFIG.DARK_BLUE};
    @media (max-width: 767px) {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
  a {
    font-size: 18px;
    line-height: 28px;
    color: ${COLOR_CONFIG.DARK_BLUE};
    font-family: ${FONT_CONFIG.nexaBook};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    @media (max-width: 767px) {
      font-size: 16px;
    }
  }
  ul {
      list-style: '-';
      padding-left: 20px;
      @media (max-width: 767px) {
        padding-left: 10px;
      }
    }
  li {
    font-size: 18px;
    line-height: 28px;
    color: ${COLOR_CONFIG.DARK_BLUE};
    font-family: ${FONT_CONFIG.nexaBook};
    margin-bottom: 10px;
    padding-left: 10px;
    &::last-of-type {
      margin-bottom: 0;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      margin-bottom: 5px;
    }
  }
`;
