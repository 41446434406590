// @flow

import Home from './Home';
// import Clients from './Clients';
import ContactUs from './ContactUs';
import TermsConditions from './TermsConditions';
import ErrorPage from './404';
import PrivacyPolicy from './PrivacyPolicy';
import CorporateResponsibility from './CorporateResponsibility';
import Hold from './Hold';
import CookiePolicy from './CookiePolicy';
import Accessibility from './Accessibility';
import OurBelief from './OurBelief';
import SingleNews from './SingleNews';
import Disclaimer from './Disclaimer';
import AcceptableUsePolicy from './AcceptableUsePolicy';
import News from './News';

export {
  Home,
  ContactUs,
  // Clients,
  TermsConditions,
  ErrorPage,
  PrivacyPolicy,
  CorporateResponsibility,
  Hold,
  CookiePolicy,
  Accessibility,
  OurBelief,
  SingleNews,
  Disclaimer,
  AcceptableUsePolicy,
  News,
};
