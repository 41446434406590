// @flow

/* REACT */
import React from 'react';

/* MODULES */
import { isMobile } from "react-device-detect";

/* CUSTOM MODULES */

/* CONFIGS */
import { MAIN_CONFIG } from './../../../../configs';

/* STYLES */
import {
  Wrapper, Title, Content, CardsList, AutoLogos,
  Card, BannerWrapper, Banner, CardTag, CardTitle,
  // LogosList, LogoItem, LogoImg,
} from './styles';

/* TYPES */
import type { Node } from 'react';

type _t_props = {|
  data: Object,
|};

const Clients = React.forwardRef((props: _t_props, ref): Node => {

  const {
    title, content, partials, image, mobile_image,
  } = props.data;

  return (
    <Wrapper ref={ref} id="clients">
      <Title>{title}</Title>
      <Content dangerouslySetInnerHTML={{ __html: content }} />
      <CardsList>
        {
          partials.map(i => (
            <Card key={i.id}>
              <BannerWrapper>
                <Banner src={`${MAIN_CONFIG.CDN_URL}${i.image}`} />
              </BannerWrapper>
              <CardTag>{i.title}</CardTag>
              <CardTitle>{i.preview}</CardTitle>
            </Card>
          ))
        }
      </CardsList>
      <AutoLogos src={`${MAIN_CONFIG.CDN_URL}${isMobile ? mobile_image : image}`} />
      {/* <LogosList>
        {
          clients.map(i => (
            <LogoItem
              key={i.logo}
              href={i.url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <LogoImg src={`${MAIN_CONFIG.CDN_URL}${i.logo}`} />
            </LogoItem>
          ))
        }
      </LogosList> */}
    </Wrapper>
  );
});

export default Clients;
