// @flow

/* REACT */
import React, { useState, useEffect } from 'react';

/* MODULES */

/* CUSTOM MODULES */
import { PageContainer } from './../../components';

/* CONFIGS */
import { MAIN_CONFIG, COLOR_CONFIG } from './../../configs';

/* STYLES */
import {
  Wrapper, Title, Content,
} from './styles';

/* TYPES */
import type { Node } from 'react';

// type _t_props = {||};

export default (): Node => {
  const [data, setData] = useState<Object>(null);

  const getData = async () => {
    try {
      const response = await fetch(`${MAIN_CONFIG.API_URL}page/accessibility`);
      const dataResp = await response.json();
      setData(dataResp.page);
    } catch (e) {
      console.warn('GET DATA Accessibility Statement ERROR', e);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  return (
    <PageContainer isLoading={!data} bgColor={COLOR_CONFIG.GRAY_LIGHT}>
      <Wrapper>
        <Title>{data?.title || 'Accessibility Statement'}</Title>
        <Content dangerouslySetInnerHTML={{ __html: data?.content }} />
      </Wrapper>
    </PageContainer>
  );
};
