// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from './../../configs';

export const PageContainer = styled.div`
  background-color: ${props => props.bgColor};
`;

export const Content = styled.div`
  position: relative;
  width: 100%;
  min-height: calc(100vh - 257px);
  height: 100%;
`;

export const CookiePopup = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 12px 15px;
  background-color: ${COLOR_CONFIG.DARK_BLUE};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
`;

export const CookieText = styled.p`
  font-size: 16px;
  color: ${COLOR_CONFIG.WHITE};
  font-family: ${FONT_CONFIG.nexaBold};
  a {
    padding-left: 3px;
    font-size: 16px;
    color: ${COLOR_CONFIG.WHITE};
    font-family: ${FONT_CONFIG.nexaRegular};
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

export const CookieButton = styled.button`
  padding-top: 3px;
  margin-left: 20px;
  width: 165px;
  height: 42px;
  border-radius: 21px;
  background-color: ${COLOR_CONFIG.BLUE};
  font-size: 16px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-family: ${FONT_CONFIG.nexaHeavy};
  border: 2px solid ${COLOR_CONFIG.BLUE};
  &:hover {
    background-color: ${COLOR_CONFIG.DARK_BLUE};
    color: ${COLOR_CONFIG.WHITE};
  }
`;
