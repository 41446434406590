// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from "./../../../../configs";

export const Container = styled.div`
  margin-bottom: 127px;
  position: relative;
  padding: 30px 0 60px;
  background: linear-gradient(to right, #fff 0%, #fff 37.5%, #0F1A3D 37.5%, #0F1A3D 100%);
  @media (max-width: 1150px) {
    background: ${COLOR_CONFIG.DARK_BLUE};
    padding: 70px 35px;
  }
  @media (max-width: 767px) {
    margin-bottom: 0;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
  @media (max-width: 1150px) {
    justify-content: flex-start;
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 78px;
  line-height: 1.2;
  color: ${COLOR_CONFIG.WHITE};
  position: relative;
  padding-top: 48px;
  padding-right: 274px;
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  margin-bottom: 30px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 130px;
    height: 18px;
    background-color: ${COLOR_CONFIG.BLUE};
  }
  @media (max-width: 1150px) {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
  @media (max-width: 767px) {
    font-size: 48px;
    padding-top: 34px;
    margin-bottom: 28px;
    &:before {
      width: 80px;
      height: 16px;
    }
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  margin-bottom: 50px;
  & > div {
    overflow: hidden;
  }
`;

export const ContactForm = styled.form`
  min-width: 340px;
  max-width: 23%;
  height: auto;
  margin-right: 22px;
  background-color: ${COLOR_CONFIG.GRAY_LIGHT};
  padding: 38px 34px 34px;
`;

export const FormTitle = styled.p`
  font-size: 18px;
  line-height: 1.5;
  color: ${COLOR_CONFIG.DARK_BLUE};
  margin-bottom: 20px;
  font-family: ${FONT_CONFIG.nexaBold};
`;

export const FormRow = styled.div`
  display: flex;
  justify-content: space-between;
  & > div {
    max-width: calc((100% - 12px) / 2);
  }
`;

export const InputWrapper = styled.div`
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  height: 38px;
  border: 1px solid ${COLOR_CONFIG.DARK_BLUE};
  margin-bottom: 24px;
  padding: 0 11px;
  font-size: 14px;
  color: ${COLOR_CONFIG.DARK_BLUE};
`;

export const FormDescription = styled.p`
  font-size: 12px;
  line-height: 1.3;
  color: ${COLOR_CONFIG.DARK_BLUE};
  margin-bottom: 21px;
`;

export const FormSubmit = styled.button`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 77px;
  height: 30px;
  font-size: 18px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-family: ${FONT_CONFIG.nexaBold};
`;

export const BtnIcon = styled.img``;

export const NewsList = styled.div`
  & > div {
    margin-bottom: 15px;
  }
`;

export const NewsSlider = styled.div`
  padding-right: 85px;
  padding-left: 30px;
  height: 257px;
  position: relative;
  overflow: hidden;
  .slick-list {
      margin: 0 -10px;
  }
  .slick-slide {
    margin: 0 10px;
  }
  @media (max-width: 1150px) {
    padding-right: 0;
    padding-left: 0;
  }
`;

export const SlideItem = styled.div`
  overflow: hidden;
  & > a {
    display: block;
    width: 100%;
    height: 257px;
    position: relative;
  }
`;

export const Banner = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const SlideBgOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(15, 26, 61, 0.4);
`;

export const SlideTitle = styled.p`
  position: absolute;
  bottom: 37px;
  left: 23px;
  padding: 5px 4px;
  background-color: rgba(0, 0, 0, 0.6);
  font-size: 18px;
  line-height: 1.2;
  color: ${COLOR_CONFIG.WHITE};
  max-width: 180px;
  max-height: 89px;
  overflow: hidden;
  font-family: ${FONT_CONFIG.nexaBold};
  @media (max-width: 767px) {
    bottom: 16px;
    left: 16px;
    max-width: 190px;
    line-height: 22px;
    max-height: 94px;
  }
`;

export const SliderBtnPrev = styled.button`
  position: absolute;
  top: 0;
  left: 20px;
  width: 57px;
  height: 100%;
  background: rgba(126, 224, 241, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  img {
    transform: rotate(180deg);
  }
  &:hover {
    background: rgba(126, 224, 241, 0.7);
  }
  @media (max-width: 1150px) {
    left: 0;
  }
`;

export const SliderBtnNext = styled.button`
  position: absolute;
  top: 0;
  right: 75px;
  width: 57px;
  height: 100%;
  background: rgba(126, 224, 241, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgba(126, 224, 241, 0.7);
  }
  @media (max-width: 1150px) {
    right: 0;
  }
`;

export const SliderBtnIcon = styled.img``;

export const DecorLine = styled.div`
  margin-right: 460px;
  margin-left: auto;
  width: 130px;
  height: 18px;
  background-color: ${COLOR_CONFIG.BLUE};
  @media (max-width: 1150px) {
    margin-right: 0;
    margin-left: 0;
  }
  @media (max-width: 1150px) {
    width: 80px;
    height: 16px;
  }
`;
