// @flow

/* MODULES */
import styled, { css } from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from "./../../../../configs";

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1246px;
  height: auto;
  padding: 0 15px;
  margin: 0 auto 127px;
  display: flex;
  position: relative;
  @media (max-width: 1200px) {
    position: static;
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    padding: 0;
  }
  @media (max-width: 767px) {
    margin: 0 auto 60px;
  }
`;

export const SliderWrapper = styled.div`
  position: absolute;
  z-index: 1;
  top: -55px;
  left: 15px;
  max-width: 556px;
  width: 100%;
  @media (max-width: 1200px) {
    position: static;
    top: 0;
    left: 0;
    margin-top: -200px;
    width: 90%;
  }
  @media (max-width: 767px) {
    width: 100%;
    padding: 0 35px;
    margin-top: -120px;
  }
`;

export const Img = styled.img`
  display: block;
  max-width: 100%;
  height: auto;
`;

export const Main = styled.div`
  max-width: 821px;
  width: 100%;
  padding: 80px 110px 120px 190px;
  margin-right: -40px;
  margin-left: auto;
  background-color: ${COLOR_CONFIG.DARK_BLUE};
  @media (max-width: 1200px) {
    max-width: 100%;
    padding: 44px 41px 250px 35px;
    margin: 0;
  }
  @media (max-width: 767px) {
    max-width: 100%;
    padding: 44px 41px 176px 35px;
    margin: 0;
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 78px;
  line-height: 1.2;
  color: ${COLOR_CONFIG.WHITE};
  position: relative;
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  margin-bottom: 20px;
  @media (max-width: 767px) {
    font-size: 48px;
    margin-bottom: 18px;
  }
`;

export const Content = styled.div`
  height: auto;
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 48px;
  font-family: ${FONT_CONFIG.nexaBook};
  color: ${COLOR_CONFIG.WHITE};
  transition: all 0.3s ease-in-out;
  strong {
    font-family: ${FONT_CONFIG.nexaHeavy};
  }
  @media (max-width: 767px) {
    font-size: 16px;
    margin-bottom: 28px;
    & > p {
      display: none;
      &:first-of-type {
        display: block;
      }
    }
  }
  ${props => props.isCollapsed && css`
    height: 92px;
    overflow: hidden;
    @media (max-width: 767px) {
      height: auto;
    }
  `}
  ${props => !props.isCollapsed && css`
    @media (max-width: 767px) {
      & > p {
        display: block;
      }
    }
  `}
`;

export const Button = styled.button`
  padding-top: 3px;
  margin: 0 auto 0 0;
  width: 165px;
  height: 42px;
  border-radius: 21px;
  background-color: ${COLOR_CONFIG.BLUE};
  font-size: 16px;
  color: ${COLOR_CONFIG.DARK_BLUE};
  font-family: ${FONT_CONFIG.nexaHeavy};
  border: 2px solid ${COLOR_CONFIG.BLUE};
  &:hover {
    background-color: ${COLOR_CONFIG.DARK_BLUE};
    color: ${COLOR_CONFIG.WHITE};
  }
`;
