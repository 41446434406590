// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from '../../configs';

export const ImgBg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (max-width: 1150px) {
    height: 640px;
    width: auto;
    max-width: 100%;
  }
`;

export const Wrapper = styled.div`
  position: absolute;
  width: 596px;
  height: auto;
  padding-top: calc(85px + 153px);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 1350px) {
    padding-top: calc(85px + 100px);
  }
  @media (max-width: 1150px) {
    padding-top: calc(85px + 50px);
  }
  @media (max-width: 767px) {
    padding-top: calc(110px + 50px);
    max-width: 100%;
  }
`;

export const Title = styled.p`
  color: ${COLOR_CONFIG.WHITE};
  font-size: 78px;
  line-height: 1.2;
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  padding: 64px 0 28px 64px;
  position: relative;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${COLOR_CONFIG.BLUE};
    width: 130px;
    height: 18px;
  }
  @media (max-width: 767px) {
    padding: 45px 35px 20px 35px;
    font-size: 48px;
    &:before {
      left: 35px;
      width: 80px;
      height: 16px;
    }
  }
`;

export const Text = styled.p`
  color: ${COLOR_CONFIG.WHITE};
  font-size: 20px;
  line-height: 1.2;
  margin-bottom: 43px;
  margin-left: 64px;
  @media (max-width: 767px) {
    margin-left: 0;
    margin-bottom: 25px;
    font-size: 18px;
    padding: 0 35px;
    & + a {
      margin-left: 35px;
    }
  }
`;
