// @flow

/* MODULES */
import styled from "styled-components";

/* CONFIGS */
import { COLOR_CONFIG, FONT_CONFIG } from "./../../../../configs";

export const Container = styled.div`
  width: 100%;
  background-color: ${COLOR_CONFIG.GRAY_LIGHT};
  padding: 174px 0 191px;
  @media (max-width: 1100px) {
    padding: 40px 35px 100px;
  }
`;

export const Wrapper = styled.div`
  width: 100%;
  max-width: 1140px;
  padding: 0 15px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  @media (max-width: 1100px) {
    flex-direction: column;
    padding: 0;
  }
`;

export const Main = styled.div`
  max-width: 500px;
  @media (max-width: 1100px) {
    max-width: 100%;
    margin-bottom: 40px;
  }
`;

export const Title = styled.p`
  text-transform: uppercase;
  font-size: 78px;
  line-height: 1.2;
  color: ${COLOR_CONFIG.DARK_BLUE};
  position: relative;
  padding-top: 55px;
  margin-bottom: 30px;
  font-family: ${FONT_CONFIG.bebasNeueRegular};
  max-width: 300px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 130px;
    height: 18px;
    background-color: ${COLOR_CONFIG.DARK_BLUE};
  }
  @media (max-width: 767px) {
    font-size: 48px;
    padding-top: 46px;
    margin-bottom: 25px;
    &:before {
      width: 80px;
      height: 16px;
    }
  }
`;

export const Content = styled.div`
  font-size: 18px;
  line-height: 1.3;
  margin-bottom: 50px;
  font-family: ${FONT_CONFIG.nexaBook};
  color: ${COLOR_CONFIG.DARK_BLUE};
  & + a {
    display: block;
  }
  @media (max-width: 767px) {
    p {
      margin-bottom: 15px;
    }
    font-size: 16px;
    margin-bottom: 28px;
  }
`;

export const Img = styled.img`
  display: block;
  max-width: 604px;
  width: 100%;
  height: auto;
  margin-right: -90px;
  @media (max-width: 1330px) {
    margin-right: 0;
    margin-left: 30px;
  }
  @media (max-width: 1100px) {
    margin: 0 auto;
  }
`;
