// @flow

// @flow

export default {
  TRANSPARENT: 'transparent',
  BLACK: '#000',
  WHITE: '#FFF',
  GRAY: '#333',
  GRAY_DARK: '#323232',
  GRAY_LIGHT: '#EAEAEA',
  GRAY_2: '#4A4A4A',
  YELLOW: 'yellow',
  GOLD: 'gold',
  DARK_BLUE: '#0F1A3D',
  BLUE: '#7EE0F1',
};
